export default {

  questionnaireSupportsLanguage: (state, getters) => (questionnaire, language) => {
    if (questionnaire.questions.length <= 1) {
      return false
    }
    return questionnaire.questions.every((question) => {
      const titleExists = !!question.title_translations[language]
      const optionsExists = !getters.questionHasOptions(question) || getters.moreThanTwoOptionsForLanguage(question.options, language)
      return titleExists && optionsExists
    })
  },

  questionnaireIsReadOnly: (state, getters) => (questionnaire) => {
    if (questionnaire && questionnaire.organization === null) {
      return true
    }

    if (getters.accountSelected) {
      const currentlySelectedOrganization = getters.organizationSelected
      if (currentlySelectedOrganization && currentlySelectedOrganization.pricing_plan.custom_questionnaire_allowed) {
        return false
      } else {
        return true
      }
    } else if (getters.currentUserOrganization.pricing_plan.custom_questionnaire_allowed) {
      return false
    }
    return false
  },

  questionHasOptions: () => (question) => {
    return ['SINGLE_SELECT', 'MULTI_SELECT', 'SINGLE_DROPDOWN','MULTI_DROPDOWN'].includes(question.type)
  },

  questionCanHaveCustomJumps: () => (question) => {
    return ['SINGLE_SELECT', 'MULTI_SELECT', 'BOOLEAN'].includes(question.type)
  },

  /**
   * takes a translation object with locale keys and translation values, a set of language objects, and an optional language locale
   * returns an array of locales for which translations are missing
   * if a language is provided, this function returns [language] if a translation is missing in that language
   * a translation is counted as missing only if translations (any non-empty string) exist in other languages and the translation is the empty string or undefined
   */
  missingTranslations: (state, getters) => (translations, organizationLanguages, language = '', missingWithOnlyOneLanguage) => {
    organizationLanguages = organizationLanguages.map(languageObject => languageObject.slug)
    // if an organization added new languages in a previous version, the questionnaire and categories will not have translation keys for those languages so need to add them here
    // in more recent versions of the software, any time an organization adds a new language, translation keys are automatically added to their questionnaires and categories
    let translationLanguages = Object.keys(translations)
    translationLanguages = translationLanguages.concat(organizationLanguages.filter(locale => translationLanguages.indexOf(locale) === -1))
    // if no translation exists in any language, then this question was probably just recently added or this is intentional, do not count as missing
    if (organizationLanguages.length > 1 && !missingWithOnlyOneLanguage && translationLanguages.every(locale => !translations[locale] || organizationLanguages.indexOf(locale) === -1)) {
      return []
    }

    if (organizationLanguages.length === 1) {
      const langToCheck = language || organizationLanguages[0]
      if (!translations[langToCheck] && missingWithOnlyOneLanguage) return [langToCheck]
      else return []
    }
    return translationLanguages.filter(locale => (!translations[locale] || translations[locale] === '') && organizationLanguages.indexOf(locale) !== -1 && (language === '' || locale === language))
  },

  numberOfMissingTranslationsInQuestionnaire: (state, getters) => (questionnaire, language = '') => {
    const organization = state.organizations.find(organization => organization.id === questionnaire.organization?.id)
    const organizationLanguages = getters.languageOptionsOfOrganization(organization)

    return questionnaire.questions.filter(question => {
      return getters.missingTranslations(question.title_translations, organizationLanguages, language, true).length > 0 ||
             getters.missingTranslations(question.description_translations, organizationLanguages, language).length > 0 ||
             (getters.questionHasOptions(question) && question.options && (!getters.ifSelectionQuestionHasAtLeastTwoOptions(question) || question.options.some(option => getters.missingTranslations(option.label_translations, organizationLanguages, language).length > 0)))
    }).length
  },

  ifSelectionQuestionHasAtLeastTwoOptions: (state, getters) => question => {
    if (getters.questionHasOptions(question)) {
      // count all the options that are non empty
      const nonEmptyOptions = question.options.filter(option => option.label_translations && Object.keys(option.label_translations).some(langSlug => option.label_translations[langSlug])).length
      return nonEmptyOptions >= 2
    } else return true // so that this function can be called for non select question
  },

  numberOfMissingTranslationsInCategories: (state, getters) => (questionnaire) => {
    const organization = state.organizations.find(organization => organization.id === questionnaire.organization?.id)
    const organizationLanguages = getters.languageOptionsOfOrganization(organization)
    const categories = questionnaire.report_categories
    return categories.filter(category => getters.missingTranslations(category.label_translations, organizationLanguages).length > 0).length
  },

  moreThanTwoOptionsForLanguage: () => (options, language) => {
    let validOptions = 0
    for (const i in options) {
      const option = options[i]
      if (option.label_translations[language]) {
        validOptions++
      }
    }
    return validOptions >= 2
  }
}
