import dayjs from "@/plugins/day"
import i18next from 'i18next'
import countries from 'i18n-iso-countries'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import fr from 'i18n-iso-countries/langs/fr.json'
import es from 'i18n-iso-countries/langs/es.json'
import it from 'i18n-iso-countries/langs/it.json'
import pl from 'i18n-iso-countries/langs/pl.json'
import nl from 'i18n-iso-countries/langs/nl.json'
import no from 'i18n-iso-countries/langs/nn.json'
import pt from 'i18n-iso-countries/langs/pt.json'
import el from 'i18n-iso-countries/langs/el.json'
import ro from 'i18n-iso-countries/langs/ro.json'
import hu from 'i18n-iso-countries/langs/hu.json'
import sl from 'i18n-iso-countries/langs/sl.json'
import sv from 'i18n-iso-countries/langs/sv.json'
import sr from 'i18n-iso-countries/langs/sr.json'
import tr from 'i18n-iso-countries/langs/tr.json'
import zh from 'i18n-iso-countries/langs/zh.json'
import ar from 'i18n-iso-countries/langs/ar.json'
import fa from 'i18n-iso-countries/langs/fa.json'
import fi from 'i18n-iso-countries/langs/fi.json'
import ja from 'i18n-iso-countries/langs/ja.json'
import ru from 'i18n-iso-countries/langs/ru.json'
import ko from 'i18n-iso-countries/langs/ko.json'
import id from 'i18n-iso-countries/langs/id.json'
import hr from 'i18n-iso-countries/langs/hr.json'
import bg from 'i18n-iso-countries/langs/bg.json'
import da from 'i18n-iso-countries/langs/da.json'
import hi from 'i18n-iso-countries/langs/hi.json'
countries.registerLocale(de)
countries.registerLocale(en)
countries.registerLocale(fr)
countries.registerLocale(es)
countries.registerLocale(it)
countries.registerLocale(pl)
countries.registerLocale(nl)
no.locale = 'no'
countries.registerLocale(no)
countries.registerLocale(pt)
countries.registerLocale(el)
countries.registerLocale(ro)
countries.registerLocale(bg)
countries.registerLocale(da)
countries.registerLocale(hi)
countries.registerLocale(hu)
countries.registerLocale(sl)
countries.registerLocale(sv)
countries.registerLocale(tr)
countries.registerLocale(sr)
countries.registerLocale(zh)
countries.registerLocale(ar)
countries.registerLocale(fa)
countries.registerLocale(fi)
countries.registerLocale(ja)
countries.registerLocale(ru)
countries.registerLocale(ko)
countries.registerLocale(id)
countries.registerLocale(hr)

export default {

  countryOptions (state) {
    const countryOptions = countries.getNames(state.language)
    const keys = Object.keys(countryOptions).sort((a, b) => countryOptions[a].localeCompare(countryOptions[b]))
    return keys.map(key => ({ key, value: countryOptions[key] }))
  },

  formatLanguageName: (state, getters) => (locale, language) => {
    const regionNames = new Intl.DisplayNames([language ?? locale], { type: 'language' })
    return getters.capitalize(regionNames.of(locale))
  },

  formatTimestampLong: () => (timestamp) => {
    return dayjs.utc(timestamp).local().format('DD.MM.YYYY, HH:mm')
  },

  formatTimestamp: () => (timestamp, format = 'DD.MM.YYYY') => {
    return dayjs.utc(timestamp).local().format(format)
  },

  formatStatus: () => (status) => {
    // return vue.$t('optionLists.reportStatus.' + status)
    return i18next.t('optionLists.reportStatus.' + status)
  },

  formatTextWithoutWordBreaks: () => (text) => {
    return text ? text.split(' ').map(text => `<nobr>${text}</nobr>`).join(' ') : ''
  },

  formatCategory: (state, getters) => (categoryId, showOrganizationName = false, language) => {
    const category = state.categories.find(category => category.id === categoryId)
    let categoryOrganizationName = null
    if (showOrganizationName) {
      categoryOrganizationName = state.reportsTableOptions.categoryOptions.find(category => category.id === categoryId)
      return category ? `${getters.formatReportCategory(category, language)}${categoryOrganizationName && categoryOrganizationName.organization_name ? ` (${categoryOrganizationName.organization_name})` : ` (${i18next.t('reportOverview.filter.defaultCategory')})`}` : ''
    }
    return category ? `${getters.formatReportCategory(category, language)}` : ''
  },

  formatReportCategory: (state) => (category, language) => {
    language = language || i18next.language
    if (category) {
      const result = category.label_translations[language] ||
        category.label_translations.en ||
        category.label_translations.de
      return result || Object.values(category.label_translations).find(translation => !!translation)
    }

    return ''
  },

  formatFollowUpAction: (state) => (followUpAction) => {
    const language = i18next.language
    if (followUpAction) {
      const result = followUpAction.label_translations[language] ||
        followUpAction.label_translations.en ||
        followUpAction.label_translations.de
      return result || Object.values(followUpAction.label_translations).find(translation => !!translation)
    }

    return ''
  },

  formatUserName: () => (user) => {
    if (user.title) {
      return `${user.title} ${user.first_name} ${user.last_name}`
    }

    return `${user.first_name} ${user.last_name}`
  },

  formatNumber: () => (value) => {
    if (value) {
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    } else {
      return ''
    }
  },

  capitalize: () => (s) => {
    if (!s) {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

}
