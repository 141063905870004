import apollo from '../../apollo'
import gql from 'graphql-tag'
import { dataURIToBlob } from './userActions'
import { PRIMARY_COLOR_CODE, ACCENT_COLOR_CODE } from '../../constants'
import {
  getOffset,
  getOrganizationsFilterQueryConditions,
  organizationFields, sortQueryBuilder
} from '../graphqlFragments'
import { assignStandardCategoriesToOrganizationsFromMetadata } from './questionnaireTemplateActions'
import vuetify from "@/plugins/vuetify";
import i18next from "i18next";

function resetColorCodes () {
  window.document.documentElement.style.setProperty('--title-text-color', PRIMARY_COLOR_CODE)
  window.document.documentElement.style.setProperty('--v-primary-base', PRIMARY_COLOR_CODE)
  window.document.documentElement.style.setProperty('--accent-color', ACCENT_COLOR_CODE)
  window.document.documentElement.style.setProperty('--v-accent-base', ACCENT_COLOR_CODE)
  vuetify.theme.themes.value['light'].colors.primary = PRIMARY_COLOR_CODE
  vuetify.theme.themes.value['light'].colors.accent = ACCENT_COLOR_CODE
}

export default {

  async createOrganization ({ commit, dispatch, getters, state }, organization) {
    const body = { ...organization }
    const responseMail = await dispatch('checkPrimaryContactMailExists', organization.primary_contact_email)
    if (!responseMail.ok) {
      return responseMail
    }

    // TODO it blocks tests
    // const responseSession = await dispatch('refreshSession')
    // if (!responseSession.ok) {
    //   return responseSession
    // }
    const customerReference = await dispatch('fetchNewCustomerReference')
    body.customer_reference = customerReference

    if (getters.isOrganizationBillwerkable(organization) && getters.isLteAdmin && !body.parent_id) {
      const customer = await dispatch('lteOrganizationToBillwerkCustomer', {
        organization: organization.alternative_billing_address ? { ...organization, ...organization.alternativeBillingAddressData } : organization
      })

      customer.DefaultBearerMedium = !organization.send_invoices_directly ? 'ArchiveOnly' : 'Email'
      try {
        const { CustomerId, ContractId } = await dispatch('signupWithInvoice', {
          customer: customer,
          plan: organization.pricing_plan,
          customerReference: customerReference,
          invoiceCustomText: organization.invoice_custom_text || null
        })

        body.pricing_plan.customer_id = CustomerId
        body.pricing_plan.contract_id = ContractId
      } catch (error) {
        commit('showDefaultError')
        throw error
      }
    }

    body.primary_contact_email = body.primary_contact_email.toLowerCase()

    // TODO review
    // body.send_invoices_directly = body.dtype === 'Sme' ? false : body.send_invoices_directly

    if (!body.parent_id) {
      body.parent = null
    }

    delete body.logo
    delete body.favicon
    const response = await dispatch('POST', {
      url: `/rest/organization/create`,
      body: JSON.stringify(body),
      handleFailure: true
    })

    if (!response.ok) {
      return response
    }

    const id = await response.json()
    // TODO parallelize these calls and check if they have to be awaited at all
    await dispatch('updateOrganizationLogos', { ...organization, id })
    await dispatch('loadOrganizations')
    await dispatch('loadOrganizationsFiltered')
    await dispatch('loadAllUsersFiltered')
    if (body.parent_id && (!getters.isLteAdmin && getters.isOwnOrganizationAdminOrSupport)) {
      // Needs refresh roles of the user who is creating the new organization as an admin
      await dispatch('refreshUserData')
    }
    return id
  },

  async updateOrganizationLogos ({ dispatch }, organization) {
    if (organization.logo !== undefined || organization.favicon !== undefined) {
      const formData = new FormData()
      if (organization.logo) {
        formData.append('logo', dataURIToBlob(organization.logo))
      } else if (organization.logo === null) {
        formData.append('resetLogo', 'true')
      }

      if (organization.favicon) {
        formData.append('favicon', dataURIToBlob(organization.favicon))
      } else if (organization.favicon === null) {
        formData.append('resetFavicon', 'true')
      }

      await dispatch('POST', {
        url: `/rest/organization/${organization.id}/update-pictures`,
        isFile: true,
        body: formData
      })
      dispatch('reloadOrganizationLogos')
    }
  },

  async updateOrganizationStartPageConfiguration ({ dispatch }, { organization, startPageConfiguration }) {
    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/update-start-page-configuration`,
      body: JSON.stringify(startPageConfiguration)
    })
  },

  async updateOrganizationCustomFiles ({ dispatch }, { organization, files, fileType }) {
    const body = new FormData()
    for (const file of files) {
      if (!file.file) {
        body.append('files', new Blob(), 'empty-custom-start-page-file.pdf')
      } else {
        body.append('files', file.file)
      }

      body.append('filenames', file.filename)
      body.append('ids', file.id ? file.id : -1)
      body.append('languages', file.language_locale)
    }
    await dispatch('POST', {
      url: `/rest/start-page/${organization.id}/update-start-page-custom-files/${fileType}`,
      body,
      isFile: true
    })
  },

  async translateProperties ({ dispatch, commit }, { organizationId, sourceLocale, targetLocales, properties, propertiesExcludedLocales }) {
    const body = {
      source_locale: sourceLocale,
      target_locales: targetLocales,
      properties_excluded_locales: propertiesExcludedLocales,
      properties: properties,
    }

    const response = await dispatch('POST', {
      url: `/rest/organization/${organizationId}/translate`,
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      commit('setErrorMessage', (i18next.t('startPageSettings.translateErrorSnackbar')))
      commit('showDefaultError')
      return
    }

    return await response.json()
  },

  /**
   * @param organizationId
   * @param files: { language: locale string, file: file intended to be uploaded for the given fileType}
   * @param fileType: a START_PAGE_FILE_TYPE
   * @return {Promise<void>}
   */
  async updateOrganizationStartPageFileUpload ({ dispatch }, { organizationId, files, fileType }) {
    const body = new FormData()
    for (const { language, file } of files) {
      body.append('files', file, `${language}_${fileType}.pdf`)
    }
    await dispatch('POST', {
      url: `/rest/start-page/${organizationId}/${fileType}`,
      body,
      isFile: true
    })
  },

  /**
   * @param organizationId
   * @param files: { language: locale string, file: file intended to be uploaded for the given fileType}
   * @param fileType: a START_PAGE_FILE_TYPE
   * @param locale: language code
   * @return {Promise<void>}
   */
  async deleteOrganizationStartPageFile ({ dispatch }, { organizationId, fileType, locale }) {
    await dispatch('DELETE', { url: `/rest/start-page/${organizationId}/${fileType}/${locale}` })
  },

  async deleteStartPageCustomFile ({ dispatch }, { organizationId, id }) {
    await dispatch('DELETE', { url: `/rest/start-page/${organizationId}/custom-file/${id}` })
  },

  async updateOrganizationNotifications ({ dispatch }, { organization, notifications }) {
    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/update-notifications`,
      body: JSON.stringify(notifications)
    })

    dispatch('loadOrganizations', { setAccounts: false })
  },

  async reloadOrganizationLogos ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query organization {
          organization {
            id
            logo_filename
            favicon_filename
          }
        }
      `
    })

    commit('reloadOrganizationLogos', response.data.organization)
  },

  async checkPrimaryContactMailExists ({ dispatch }, email) {
    return await dispatch('POST', {
      url: `/rest/organization/check-primary-contact-mail-exists?email=${email}`,
      handleFailure: false
    })
  },

  async updateOrganization ({ state, commit, dispatch, getters }, organization) {
    const body = { ...organization }

    if (getters.isOrganizationBillwerkable(organization) && getters.isLteAdmin && !organization.parent && organization.pricing_plan.contract_id) {
      const token = await dispatch('getSelfServiceToken', { contractId: organization.pricing_plan.contract_id })
      // eslint-disable-next-line
      const portal = new SubscriptionJS.Portal(token.Token)
      const customerData = await dispatch('lteOrganizationToBillwerkCustomer', {
        organization: organization.alternative_billing_address ? organization.alternativeBillingAddressData : organization
      })

      await dispatch('changeCustomerData', { portal: portal, customerData })
    }

    delete body.dtype
    delete body.id

    await dispatch('updateOrganizationLogos', organization)
    delete body.logo
    delete body.favicon
    dispatch('changeFavicon')
    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/update`,
      body: JSON.stringify(body)
    })

    dispatch('loadOrganizationsFiltered')
    dispatch('loadOrganizations', { setAccounts: false })
    dispatch('loadUserData', { sessionExpired: false, setAccounts: false })
  },

  async setOrganizationActive ({ commit, dispatch }, { organization, active }) {
    const body = new FormData()
    body.append('active', active)

    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/set-active`,
      body: body,
      isFile: true
    })
    dispatch('loadOrganizations')
    dispatch('loadOrganizationsFiltered')
  },

  async setOrganizationSuspended ({ commit, dispatch }, { organization, suspend }) {
    const body = new FormData()
    body.append('suspended', suspend)

    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/set-suspended`,
      body: body,
      isFile: true
    })

    dispatch('loadOrganizations')
    dispatch('loadOrganizationsFiltered')
  },

  async assignParentToOrganization ({ commit, dispatch, getters }, { organization, parentId }) {
    // eslint-disable-next-line camelcase
    if (getters.isOrganizationBillwerkable(organization) && organization.pricing_plan?.contract_id) {
      await dispatch('createPortalAndCancelContract', organization.pricing_plan.contract_id)
    }
    const body = new FormData()
    body.append('parent-id', parentId)

    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/assign-parent`,
      body: body,
      isFile: true
    })

    dispatch('loadOrganizations')
    dispatch('loadOrganizationsFiltered')
  },

  async releaseOrganization ({ commit, dispatch, getters }, organization) {
    // eslint-disable-next-line camelcase
    if (getters.isOrganizationBillwerkable(organization) && organization.pricing_plan?.contract_id) {
      await dispatch('createPortalAndCancelContract', organization.pricing_plan.contract_id)
    }

    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/release-parent`
    })

    dispatch('loadOrganizations')
    dispatch('loadOrganizationsFiltered')
    dispatch('loadAllUsersFiltered')
  },

  async deleteOrganization ({ commit, dispatch }, organization) {
    await dispatch('DELETE', {
      url: `/rest/organization/${organization.id}/delete`
    })
    await dispatch('loadOrganizationsFiltered')
    await dispatch('loadOrganizations')
    await dispatch('loadAllUsersFiltered')
  },

  async loadOrganizationsFiltered ({ state, commit, dispatch, getters }) {
    if (getters.isTranslator) {
      console.warn('Translators should not attempt loading organizations')
      return
    }
    const isAssociatedPartner = state.organizationsTableOptions.filter.managedByLawfirm.length > 0
    let employeeSearch = null
    if (isNaN(state.organizationsTableOptions.search)) {
      employeeSearch = 0
    } else {
      employeeSearch = parseInt(state.organizationsTableOptions.search)
    }
    const conditions= getOrganizationsFilterQueryConditions(
      getters.isLteAdmin, isAssociatedPartner,
      state.organizationsTableOptions.search || '',
      typeof state.accountsFilter === 'string' ? [state.accountsFilter] : state.accountsFilter,
      state.organizationsTableOptions.filter.managedByLawfirm.map(lawfirm => lawfirm.id),
      employeeSearch)
    const order = sortQueryBuilder(state.organizationsTableOptions.orderBy)
    const response = await apollo(state).query({
      query: gql`
          query organization($order_by: [organization_order_by!], $where: organization_bool_exp!, $limit: Int, $offset: Int) {
              organization(
                  order_by: $order_by,
                  where: $where,
                  limit: $limit,
                  offset: $offset
              ) {
                  ${organizationFields(getters.isWhb || getters.isCallOperator, getters.isOrganizationAdminOrSupport, getters.isLteAdmin)}
              }
              organization_aggregate(where: $where) {
                  aggregate {
                    count
              }
            }
          }
      `,
      variables: {
        offset: getOffset(state.organizationsTableOptions.paginator.page, state.organizationsTableOptions.paginator.itemsPerPage),
        limit: state.organizationsTableOptions.paginator.itemsPerPage,
        where: conditions,
        order_by: order,
      }
    })

    // Remove parent organizations
    if (state.currentOrganizationId || state.userData) {
      const currentOrgId = state.currentOrganizationId || state.userData.organization_id
      let parent = response.data.organization.find(organization => organization.id === currentOrgId)?.parent
      while (parent) {
        const parentIndex = response.data.organization.findIndex(organization => organization.id === parent.id)

        if (parentIndex === -1) {
          break
        }

        parent = response.data.organization[parentIndex].parent
        response.data.organization.splice(parentIndex, 1)
        response.data.organization_aggregate.aggregate.count--
      }
    }

    if (state.userData) {
      mapStatusToOrganization(response.data.organization)
    }
    commit('setFilteredOrganizations', {data: response.data.organization, totalCount: response.data.organization_aggregate.aggregate.count})
  },

  /**
   * Only used by call operators
   */
  async searchOrganizations ({ state, commit, dispatch, getters }) {
    const search = state.organizationsTableOptions.search
    if (!search || search.length < 3) {
      commit('setFilteredOrganizations', {data:[], totalCount:0})
      return
    }
    const response = await dispatch('GET', {
      url: '/rest/organization/search?' + new URLSearchParams({
        q: search
      })
    })

    const result = await response.json()
    if (state.userData) {
      mapStatusToOrganization(result)
    }
    assignStandardCategoriesToOrganizationsFromMetadata(state.categories, result, state.questionnaireTemplates)

    commit('setFilteredOrganizations', {data:result, totalCount: result.length})
  },

  async signup ({ state, dispatch }) {
    const body = JSON.parse(JSON.stringify(state.signupSme))
    body.default_language = state.language || 'en'
    delete body.pricing_plan.id

    return dispatch('POST', {
      url: '/rest/organization/signup',
      body: JSON.stringify(body),
      handleFailure: false
    })
  },

  updateColorCodes ({ state, getters }) {
    if (state.userData || state.currentOrganizationId) {
      const accountsFilterArray = getters.accountsFilterArray
      const currentOrganization = (accountsFilterArray?.length === 1) ? state.organizations.find(organization => organization.id === accountsFilterArray[0]) : getters.ownOrganization
      const parent = state.organizations.find(org => org.id === currentOrganization?.parent?.id)
      if (currentOrganization) {
        let primaryColor = PRIMARY_COLOR_CODE
        let accentColor = ACCENT_COLOR_CODE
        // eslint-disable-next-line camelcase
        if (getters.allowedToEditBranding || parent?.pricing_plan.branding_allowed) {
          if (parent) {
            primaryColor = currentOrganization.color_code_primary || parent.color_code_primary || primaryColor
            accentColor = currentOrganization.color_code_secondary || parent.color_code_secondary || accentColor
          } else {
            primaryColor = currentOrganization.color_code_primary || primaryColor
            accentColor = currentOrganization.color_code_secondary || accentColor
          }
        }
        window.document.documentElement.style.setProperty('--title-text-color', primaryColor)
        window.document.documentElement.style.setProperty('--v-primary-base', primaryColor)
        window.document.documentElement.style.setProperty('--accent-color', accentColor)
        window.document.documentElement.style.setProperty('--v-accent-base', accentColor)
        vuetify.theme.themes.value['light'].colors.primary = primaryColor
        vuetify.theme.themes.value['light'].colors.accent = accentColor
      } else {
        resetColorCodes()
      }
    } else {
      resetColorCodes()
    }
  },

  async setBrandingSetupCompleted ({ dispatch, getters }) {
    await dispatch('POST', {
      url: `/rest/organization/${getters.currentUserOrganization.id}/set-branding-setup-completed?completed=true`
    })

    dispatch('loadOrganizations')
  },

  async syncContractFromBillwerk ({ dispatch }, contractId) {
    await dispatch('POST', {
      url: `/rest/billwerk/trigger-contract-changed?contract-id=${contractId}`
    })

    dispatch('loadOrganizations')
    dispatch('loadOrganizationsFiltered')
  },

  async syncCustomerReferenceFromBillwerk ({ commit, dispatch }, { organization }) {
    await dispatch('POST', {
      url: `/rest/organization/${organization.id}/synchronize-customer-reference`
    })
  },

  async downloadOrganizationAuditLogs({state, dispatch}, organizationId) {
    return await dispatch('GET', {
      url: `/rest/organization/${organizationId}/audit-trail`,
    })
  },

}

function mapStatusToOrganization (organizations) {
  organizations.map(el => {
    if (!el.active) {
      el.status = 'disabled'
    } else if (el.suspended) {
      el.status = 'suspended'
    } else {
      el.status = 'active'
    }
  })
}
