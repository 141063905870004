<template>
<v-dialog :model-value="modelValue" :persistent="true">
  <!--v-overlay class="overlay">

</v-overlay-->
  <div style="width: 100vw; height: 100vh; display: grid; align-content: center; justify-content: center">
    <v-progress-circular
      :size="100"
      color="primary"
      indeterminate></v-progress-circular>
  </div>
</v-dialog>

</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    modelValue: {
      type: Boolean
    }
  }
}
</script>
<style>

</style>
