export default {

  billwerkApiKey () {
    const devKey = '5f646f948f365522ea0133c0'
    const testKey = '5f857bc0aa06bb39220ddf4e'
    const demoKey = '6013dd46ef105f12738a7eff'
    const prodKey = '5f7ad544a44182324e7e7b3d'

    if (['localhost'].includes(window.location.hostname)) {
      return devKey
    } else if (['app-dev.whistle-report.com', 'app-dev-internal.whistle-report.com'].includes(window.location.hostname)) {
      return devKey
    } else if (['app-test.whistle-report.com', 'app-test-internal.whistle-report.com'].includes(window.location.hostname)) {
      return testKey
    } else if (['demo.whistle-report.com', 'demo-internal.whistle-report.com'].includes(window.location.hostname)) {
      return demoKey
    } else if (['app.whistle-report.com', 'app-internal.whistle-report.com'].includes(window.location.hostname)) {
      return prodKey
    }
  },

  billwerkProvider (state, getters) {
    return getters.isLawFirm ? ['Debit:PayOne', 'OnAccount:PayOne'] : ['CreditCard:PayOne', 'Debit:PayOne', 'OnAccount:PayOne']
  },

  formatPrice: () => (price) => {
    const parts = String(price).split('.')
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + (parts[1] ? (parts[1].length === 1 ? parts[1] + '0' : parts[1]) : '00') + ' €'
  }

}
