import apollo from '../../apollo'
import gql from 'graphql-tag'
import router from '../../router'
import dayjs from "@/plugins/day"
import {completeReportFields, reportTableFields, uploadedFiles} from '../graphqlFragments'
import {atobWithFallback} from "../../util";
import {isValidPhoneNumber} from "libphonenumber-js";

export default {

  async createReport({dispatch, state, commit, getters},captchaSolution = "") {
    const reportStatement = {...state.reportStatement}
    delete reportStatement.files
    const body = new FormData()
    body.append('statements', JSON.stringify(reportStatement))
    body.append('statementValues', Object.values(reportStatement).join(''))
    body.append('organizationId', state.currentOrganizationId)
    body.append('categoryId', state.reportStatement.category)
    body.append('language', state.language)
    body.append('captchaSolution', captchaSolution)
    const questions = JSON.parse(JSON.stringify(getters.questions))
    const visitedIds = state.visitedQuestions.map(question => question.id)
    for (const question of questions) {
      if (visitedIds.includes(question.id)) {
        question.visited = true
      }
    }
    body.append('questions', JSON.stringify(questions))

    if (state.reportStatement.files && Array.isArray(state.reportStatement.files)) {
      for (const file of state.reportStatement.files) {
        body.append('files', file)
      }
    }

    const response = await dispatch('POST', {
      url: '/rest/report/create',
      body: body,
      isFile: true
    })
    const result =  await response.json()
    commit('setCurrentReportId',result.report_id)
    return result.case_manager_full_name;
  },

  addPin({dispatch, state}, pin) {
    return dispatch('POST', {
      url: `/rest/report/${state.currentReportId || state.currentReport.id}/add-pin?pin=${pin}`
    })
  },

  async addEmail({dispatch, state}, email) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/add-email?email=${email.toLowerCase()}`
    })
  },

  async loadAllReportsFiltered({state, commit, getters, dispatch}) {
    // Filter followUpActions with label matching search text (hasura does not support _ilike for jsob column)
    const followUpActionIds = !state.reportsTableOptions.search ? [] : state.followUpActions
      .filter(followUpAction => {
        const label = followUpAction.label_translations[state.language] || followUpAction.label_translations.en
        return label.toLowerCase().indexOf(state.reportsTableOptions.search.toLowerCase()) > -1
      }).map(followUpActions => followUpActions.id)

    const response = await apollo(state).query({
      query: gql`
        query report($deleted: String, $search: String, $followUpActionIds: [uuid!], $statusFilter: [String!], $caseManagerFilter: [uuid!], $categoryFilter: [uuid!], $followUpActionFilter: [uuid!], $dateFrom: timestamp, $dateTo: timestamp, $accounts: [uuid!]) {
          report(
            where: {
            _or: [
              {
                organization: {
                  name: {_ilike: $search}
                }
              },
              {
                statement_values: {
                  _ilike: $search
                }
              },
              {
                chat_messages: {
                  text: {_ilike: $search}
                }
              },
              {
                internal_notes: {
                  text: {_ilike: $search}
                }
              }
              {
                report_follow_up_actions: {
                  text: {_ilike: $search}
                }
              }
              ${followUpActionIds.length ? `
              {
                report_follow_up_actions: {
                  follow_up_action_id: {_in: $followUpActionIds}
                }
              }` : ''}
            ],status: {
            ${state.reportsTableOptions.filter.status.length ? `
              _in: $statusFilter }` : '_neq : $deleted }'}
            ${state.reportsTableOptions.filter.caseManager.length ? `
            ,case_manager_id: {
              _in: $caseManagerFilter
            }` : ''}
            ${state.reportsTableOptions.filter.category.length ? `
            ,category_id: {
              _in: $categoryFilter
            }` : ''}
            ${state.reportsTableOptions.filter.followUpAction.length ? `
            ,report_follow_up_actions: {
              follow_up_action_id: {_in: $followUpActionFilter}
            }` : ''}
            ${state.reportsTableOptions.filter.date.length ? `
            ,submitted: {
              _gte: $dateFrom,
              _lte: $dateTo
            }` : ''}
            ${state.accountsFilter.length ? 'organization_id: { _in: $accounts }' : ''}
          }) {
            ${reportTableFields(state.userData)}
          }
        }
      `,
      variables: {
        deleted: 'DELETED',
        search: '%' + (state.reportsTableOptions.search || '') + '%',
        followUpActionIds: followUpActionIds,
        statusFilter: state.reportsTableOptions.filter.status,
        caseManagerFilter: state.reportsTableOptions.filter.caseManager.map(user => user.id),
        categoryFilter: state.reportsTableOptions.filter.category,
        followUpActionFilter: state.reportsTableOptions.filter.followUpAction,
        dateFrom: getters.dateFilterSorted[0] ? dayjs(getters.dateFilterSorted[0], 'YYYY-MM-DD').startOf('day').format() : '',
        dateTo: getters.dateFilterSorted[1] ? dayjs(getters.dateFilterSorted[1], 'YYYY-MM-DD').endOf('day').format() : (getters.dateFilterSorted[0] ? dayjs(getters.dateFilterSorted[0], 'YYYY-MM-DD').endOf('day').format() : ''),
        accounts: state.accountsFilter
      }
    })
    const reports = response.data.report.map(report => {
      return {
        ...report,
        is_read: report.notifications.every(n => n.read)
      }
    })
    commit('setReports', reports)
  },

  async loadCaseManagerOptions({state, commit, dispatch}) {
    if (!state.accountsFilter.length) {
      return
    }
    const response = await apollo(state).query({
      query: gql`
        query report($accounts: [uuid!]) {
          report (distinct_on: case_manager_id, where: {${state.accountsFilter.length ? ' organization_id: { _in: $accounts }' : ''}})  {
            case_manager {
              id
              title
              first_name
              last_name
            }
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter
      }
    })

    commit('setCaseManagerOptions', response.data.report.map(report => report.case_manager).filter(caseManager => !!caseManager))
  },

  async loadCategoryOptions({state, commit, dispatch}) {
    if (!state.accountsFilter.length) {
      return
    }

    const response = await apollo(state).query({
      query: gql`
        query report($accounts: [uuid!], $deleted: String) {
          report (distinct_on: category_id, where: { status: { _neq : $deleted }${state.accountsFilter.length ? ', organization_id: { _in: $accounts }' : ''}})  {
            organization{
              name
            }
            report_category {
              id
              label_translations
              organization{
                name
              }
            }
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter,
        deleted: 'DELETED'
      }
    })

    commit('setCategoryOptions', response.data.report.map(report => {
      if (report.report_category.organization !== null) {
        report.report_category.organization_name = report.organization.name
      }
      return report.report_category
    }).filter(category => !!category))
  },

  async loadFollowUpActionOptions({state, commit}) {
    if (!state.accountsFilter.length) {
      return
    }
    const response = await apollo(state).query({
      query: gql`
        query report_follow_up_action($accounts: [uuid!]) {
          report_follow_up_action (distinct_on: follow_up_action_id, where: {${state.accountsFilter.length ? ' report: {organization_id: { _in: $accounts }}' : ''}})  {
            follow_up_action {
              id
              display_order
              label_translations
            }
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter
      }
    })
    commit('setFollowUpActionOptions', response.data.report_follow_up_action.map(report => report.follow_up_action).filter(followUpAction => !!followUpAction).sort((a, b) => a.display_order - b.display_order))
  },

  async loadReportFollowUpAction({state, commit}) {
    if (!state.currentReport) {
      return
    }
    const response = await apollo(state).query({
      query: gql`
        query report($reportId: uuid) {
          report (where: {id: {_eq: $reportId}})  {
            report_follow_up_actions {
              follow_up_action_id
              text
              updated
            }
          }
        }
      `,
      variables: {
        reportId: state.currentReport.id
      }
    })

    commit('setReportFollowUpActions', response.data.report[0].report_follow_up_actions)
  },

  async loadCurrentReport({state, commit, dispatch, getters}) {
    if (!(getters.isGlobalUser || getters.isWhb)) await dispatch('loadFollowUpActions')
    const loadById = state.currentReportId !== null && state.currentReportId !== undefined
    const loadByNumber = state.currentReportNumber !== null && state.currentReportNumber !== undefined
    const requestIsByNumberOrId = loadById || loadByNumber

    if (!requestIsByNumberOrId) {
      throw new Error('Either report id or report number must be present')
    }

    let response = null
    if (loadById) {
      response = await apollo(state).query({
        query: gql`
        query report($reportId: uuid, $deleted: String) {
          report (where: {id: {_eq: $reportId}, status: {_neq: $deleted}})  {
            ${
          !getters.isCallOperator ? completeReportFields(state.userData) : `
              report_number
                id
                `
        }
            }
          }
          `,
        variables: {
          reportId: state.currentReportId,
          deleted: 'DELETED'
        }
      })
    } else {
      response = await apollo(state).query({
        query: gql`
        query report($reportNumber: bigint) {
          report (where: {report_number: {_eq: $reportNumber}})  {
            ${
          !getters.isCallOperator ? completeReportFields(state.userData) : `
              report_number
                id
                `
        }
            }
          }
          `,
        variables: {
          reportNumber: state.currentReportNumber
        }
      })
    }

    if (!response.data.report.length) {
      const response = await dispatch('addReportToSession', state.currentReportNumber)
      if (response.ok) {
        router.push({
          name: 'ViewReportPin', query: {language: state.language}
        })
      } else {
        router.push({
          name: 'Report', query: {language: state.language}
        })
      }
      return
    }
    const report = response.data.report[0]
    commit('setCurrentReport', report)

    if (state.userData) {
      if (report.status === 'PENDING' && !report.case_manager && getters.hasRoleForOrganization(report.organization_id, ['ADMIN', 'GENERAL_CASE_MANAGER'])) {
        dispatch('assignCaseManager', state.userData.id)
      }
    }

    if (!getters.isCallOperator) {
      dispatch('markReportAsRead')
    }
  },

  async loadReportTranslations({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query report($reportId: uuid, $deleted: String) {
          report (where: {id: {_eq: $reportId}, status: {_neq: $deleted}})  {
              statements_translations
            }
          }
          `,
      variables: {
        reportId: state.currentReportId,
      }
    })

    const report = response.data.report[0]
    commit('loadReportTranslations', report.statements_translations)
  },

  async loadFollowUpActions({state, commit, dispatch, getters}) {
    const response = await apollo(state).query({
      query: gql`
      query follow_up_action {
        follow_up_action {
          display_order
          id
          label_translations
        }
      }
      `
    })

    commit('setFollowUpActions', response.data.follow_up_action)
  },

  async subscribeToFileChanges({state, commit}) {
    if (state.fileSubscription) {
      state.fileSubscription.unsubscribe()
    }

    const observer = (apollo(state).subscribe({
      query: gql`
        subscription report($reportId: uuid) {
          report (where: {id: {_eq: $reportId}})  {
            id
            ${uploadedFiles(state.userData)}
          }
        }
      `,
      variables: {
        reportId: state.currentReport.id
      }
    }))

    const subscription = observer.subscribe({
      next(data) {
        if (!state.currentReport) {
          return
        }
        const invalidId = data.data.report[0].id !== state.currentReport.id

        if (invalidId) {
          return
        }

        commit('setCurrentReportFiles', data.data.report[0].uploaded_files)
      },
      error(error) {
        console.error('subscription error', error)
      }
    })

    commit('setFileSubscription', subscription)
  },

  async addFiles({dispatch, state}, {files, isChat, internal}) {
    const body = new FormData()
    for (const file of files) {
      body.append('files', file)
    }

    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/add-files?chat=${isChat}&internal=${internal}`,
      body: body,
      isFile: true
    })
  },

  async confirmReceipt({dispatch, state, commit}, text) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/confirm-receipt`,
      body: text
    })

    commit('setReportStatus', 'IN_PROGRESS')
  },

  async translateReport({ dispatch, state }) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/translate`
    })
    await dispatch('loadReportTranslations')
  },

  async moveToReview({dispatch, state, commit}, text) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/move-to-review`,
      body: text
    })

    commit('setReportStatus', 'REVIEW')
  },

  async closeReport({dispatch, state, commit}, text) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/close`,
      body: text
    })

    commit('setReportStatus', 'CLOSED')
  },

  async archiveReport({dispatch, state, commit}) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/archive`
    })

    commit('setReportStatus', 'ARCHIVED')
  },

  async deleteFile({dispatch, state}, file) {
    dispatch('DELETE', {
      url: `/rest/report/${state.currentReport.id}/files/${file.id}`
    })
  },

  async updateReportFollowUpActions({state, dispatch, commit}, reportFollowUpActions) {
    await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/update-follow-up-actions`,
      body: JSON.stringify(reportFollowUpActions)
    })

    await dispatch('loadReportFollowUpAction')
  },

  async updateCategory({state, dispatch, commit}, categoryId) {
    if (!categoryId) {
      return
    }
    dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/update-category?category-id=${categoryId}`
    })
    commit('setCategory', categoryId)
  },

  async updateCountry({state, dispatch, commit}, country) {
    if (!country) {
      return
    }
    dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/update-country?country=${country}`
    })
    commit('setCountry', country)
  },

  async updateCategoryLawType({state, dispatch, commit}, categoryLawType) {
    if (!categoryLawType) {
      return
    }
    dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/update-category-law-type?category-law-type=${categoryLawType}`
    })
    commit('setCategoryLawType', categoryLawType)
  },

  async assignCaseManager({state, dispatch, commit}, userId) {
    dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/assign-case-manager?user-id=${userId}`
    })
    commit('setCaseManager', userId)
  },

  async addCaseReader({state, dispatch, commit}, userId) {
    dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/add-case-reader?user-id=${userId}`
    })
    commit('addCaseReader', userId)
  },

  async removeCaseReader({state, dispatch, commit}, userId) {
    dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/remove-case-reader?user-id=${userId}`
    })
    commit('removeCaseReader', userId)
  },

  async markReportAsRead({state, dispatch, commit}, reportId) {
    reportId = reportId || state.currentReportId || state.currentReport?.id
    commit('markReportAsReadUnread', {reportId: reportId, isRead: true})
    await dispatch('POST', {
      url: `/rest/report/${reportId}/mark-read`
    })
  },
  async markReportAsUnread({state, dispatch, commit}, reportId) {
    reportId = reportId || state.currentReportId || state.currentReport?.id
    commit('markReportAsReadUnread', {reportId: reportId, isRead: false})
    await dispatch('POST', {
      url: `/rest/report/${reportId}/mark-unread`
    })
  },
  downloadReportFile({dispatch, state}, {reportId, fileId}) {
    return dispatch('GET', {
      url: `/rest/report/${reportId}/files/${fileId}`
    })
  },
  downloadReportAllFiles({dispatch, state}, {reportId}) {
    return dispatch('GET', {
      url: `/rest/report/${reportId}/files/all`
    })
  },
  async download({state, dispatch}, reportIds) {
    reportIds = reportIds != null ? reportIds : [state.currentReportId]
    return await dispatch('POST', {
      url: '/rest/report/download',
      body: JSON.stringify({
        reportIds,
        language: state.language
      })
    })
  },
  async downloadAuditLog({state, dispatch}, reportId) {
    reportId = reportId != null ? reportId : state.currentReportId
    return await dispatch('GET', {
      url: `/rest/report/${reportId}/audit-trail`,
    })
  },


  async downloadOverview({state, dispatch}, {
    reportIds,
    accountsFilter,
    reportsTableOptions,
    statusOptions,
    categories
  }) {
    const organizationFilter = Array.isArray(accountsFilter) ? accountsFilter : [accountsFilter]
    return await dispatch('POST', {
      url: '/rest/report/overview/download',
      body: JSON.stringify({
        reportIds,
        caseManagerOptions: reportsTableOptions.caseManagerOptions.map(caseManager => caseManager.id),
        caseManagerFilter: reportsTableOptions.filter.caseManager.map(caseManager => caseManager.id),
        categoryOptions: categories
          .filter(category => organizationFilter.includes(category.organization_id))
          .map(category => category.id)
          .filter((value, idx, a) => a.indexOf(value) === idx),
        categoryFilter: reportsTableOptions.filter.category,
        statusOptions,
        statusFilter: reportsTableOptions.filter.status,
        followUpActionOptions: reportsTableOptions.followUpActionOptions.map(followUpAction => followUpAction.id),
        followUpActionFilter: reportsTableOptions.filter.followUpAction,
        dateFilter: reportsTableOptions.filter.date,
        organizationFilter,
        search: reportsTableOptions.search,
        language: state.language
      })
    })
  },
  async loadReportPermission({state, commit}) {
    if (!state.currentReport) {
      return
    }
    const response = await apollo(state).query({
      query: gql`
        query report($reportId: uuid) {
          report_permission(where: {report_id: {_eq: $reportId}}) {
            permission
            lte_user {
              id
              email
              first_name
              last_name
              title
              organization {
                name
              }
            }
          }
        }
      `,
      variables: {
        reportId: state.currentReport.id
      }
    })

    commit('setReportPermissions', response.data.report_permission)
  },
  async updatePersonalInformationViolations({state, dispatch, getters}, {
    fieldKey,
    onlyRemove = false,
    value,
    validateCustomValue = false,
    settings = {},
  }) {
    const subFields = validateCustomValue ? value : state.reportStatement[fieldKey]


    for (const [key, value] of Object.entries(getters.personalInformationSubFields)) {
      if (!onlyRemove && (value.mandatory === true || settings[`mandatory_${value.key}`] === true) && !subFields?.[value.key]) {
        await dispatch('addInternalValidation', value.key)
      }
      if (subFields?.mode === 'ANONYMOUS' || ((value.mandatory === true || settings[`mandatory_${value.key}`] === true) && subFields?.[value.key])) {
        await dispatch('removeInternalValidation', value.key)
      }
    }

    if (!onlyRemove && settings.mandatory_phone === true && subFields && subFields.phone && !isValidPhoneNumber(atobWithFallback(subFields.phone))) {
      await dispatch('addInternalValidation', 'phone')
    }
    if (settings.mandatory_phone === true && subFields?.mode === 'ANONYMOUS' || (subFields?.phone && isValidPhoneNumber(atobWithFallback(subFields.phone)))) {
      await dispatch('removeInternalValidation', 'phone')
    }

    if (!onlyRemove && subFields && subFields.email && !getters.emailRegex.test(atobWithFallback(subFields.email))) {
      await dispatch('addInternalValidation', 'email')
    }
    if (subFields?.mode === 'ANONYMOUS' || (subFields?.email && getters.emailRegex.test(atobWithFallback(subFields.email)))) {
      await dispatch('removeInternalValidation', 'email')
    }

    if (!onlyRemove && subFields && subFields.email !== subFields.confirmEmail) {
      await dispatch('addInternalValidation', 'confirmEmail')
    }
    if (subFields?.mode === 'ANONYMOUS' || (subFields && subFields.email === subFields.confirmEmail)) {
      await dispatch('removeInternalValidation', 'confirmEmail')
    }
  },

  async updatePersonalInformationReportPermissionAssignment({state, dispatch, commit}, to) {
    return await dispatch('POST', {
      url: `/rest/report-permission/${state.currentReport.id}/update-personal-information`,
      body: JSON.stringify(to)
    })
  },

  async requestDeletion({state, dispatch, commit}, { deletionJustification }) {
    await dispatch('DELETE', {
      url: `/rest/report/${state.currentReport.id}/request-deletion?justification=${deletionJustification}`
    })
    await dispatch('loadCurrentReport')
  },

  async confirmDeletion({state, dispatch}) {
    await dispatch('DELETE', {
      url: `/rest/report/${state.currentReport.id}/confirm-deletion`
    })
    await dispatch('loadCurrentReport')
  },

  async rejectDeletion({state, dispatch}) {
    await dispatch('DELETE', {
      url: `/rest/report/${state.currentReport.id}/reject-deletion`
    })
    await dispatch('loadCurrentReport')
  }

}
