export default {
  createCart ({ state }, plan) {
    const additionalUsers = plan.user_accounts - plan.initialAccounts
    const additionalLanguages = plan.languages - plan.initialLanguages

    const cart = {
      planVariantId: plan.plan_variant_id,
      ComponentSubscriptions: []
    }

    if (additionalUsers) {
      cart.ComponentSubscriptions.push({
        ComponentId: plan.user_component_id,
        Quantity: additionalUsers
      })
    }

    if (additionalLanguages) {
      cart.ComponentSubscriptions.push({
        ComponentId: plan.language_component_id,
        Quantity: additionalLanguages
      })
    }

    return cart
  },

  preview ({ state }, { signupService, cart, customer }) {
    return new Promise((resolve, reject) => {
      signupService.preview(cart, customer, resolve, reject)
    })
  },

  processPaymentData ({ state }, { paymentForm, totalGross, currency }) {
    return new Promise((resolve, reject) => {
      paymentForm.processPaymentData(totalGross, currency, resolve, reject)
    })
  },

  async fetchNewCustomerReference ({ dispatch }) {
    const response = await dispatch('GET', {
      url: '/rest/organization/generate-customer-reference'
    })
    return await response.text()
  },

  createOrder ({ state }, {
    signupService, cart, customer,
    contractCustomFields = {}, customFields = {}
  }) {
    return new Promise((resolve, reject) => {
      signupService.createOrder({
        Cart: cart,
        Customer: customer,
        ContractCustomFields: contractCustomFields,
        CustomFields: customFields
      }, resolve, reject)
    })
  },

  paySignupInteractive ({ state }, { signupService, paymentService, paymentData, order }) {
    return new Promise((resolve, reject) => {
      signupService.paySignupInteractive(paymentService, paymentData, order, resolve, reject)
    })
  },

  finalize () {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      SubscriptionJS.finalize(resolve, reject)
    })
  },

  lteOrganizationToBillwerkCustomer ({ state }, { organization }) {
    return {
      FirstName: organization.primary_contact_first_name,
      LastName: organization.primary_contact_last_name,
      EmailAddress: organization.primary_contact_email,
      Address: {
        Street: organization.street_address,
        HouseNumber: '',
        PostalCode: organization.zip_code,
        City: organization.city,
        Country: organization.country
      },
      CompanyName: organization.name,
      VatId: organization.vat_id
    }
  },

  lteOrganizationToBillwerkCustomerSnakeCase ({ state }, { organization }) {
    return {
      first_name: organization.primary_contact_first_name,
      last_name: organization.primary_contact_last_name,
      email_address: organization.primary_contact_email,
      address: {
        street: organization.street_address,
        house_number: '',
        postal_code: organization.zip_code,
        city: organization.city,
        country: organization.country
      },
      company_name: organization.name,
      vat_id: organization.vat_id
    }
  },

  createPayment ({ state }, { publicApiKey, providerReturnUrl }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      const payment = new SubscriptionJS.Payment(
        {
          publicApiKey,
          providerReturnUrl
        },
        () => resolve(payment),
        reject
      )
    })
  },

  paymentChange ({ state }, { portal, paymentForm }) {
    return new Promise((resolve, reject) => {
      portal.paymentChange(null, paymentForm, resolve, reject)
    })
  },

  async signupInteractive ({ dispatch }, { organization, customer, paymentForm }) {
    // eslint-disable-next-line no-undef
    const signupService = new SubscriptionJS.Signup()
    const cart = await dispatch('createCart', organization.pricing_plan)

    const previewData = await dispatch('preview', {
      signupService,
      cart,
      customer: customer
    })

    const processedPaymentData = await dispatch('processPaymentData', {
      paymentForm: paymentForm,
      totalGross: previewData.Order.TotalGross,
      currency: previewData.Order.Currency
    })

    const customerReference = await dispatch('fetchNewCustomerReference')
    organization.customer_reference = customerReference
    const order = await dispatch('createOrder', {
      signupService,
      cart,
      customer: customer,
      contractCustomFields: {
        CustomerReference: customerReference
      }
    })

    return await dispatch('paySignupInteractive', {
      signupService: signupService,
      paymentService: null,
      paymentData: processedPaymentData,
      order: order
    })
  },

  async upgradePayInteractive ({ state }, { portal, paymentForm, order }) {
    return new Promise((resolve, reject) => {
      portal.upgradePayInteractive(null, paymentForm, order, resolve, reject)
    })
  },

  async extendPlan ({ dispatch, getters }, { organization, paymentForm }) {
    const response = await dispatch('POST', {
      url: `/rest/organization/${organization.id}/extend-sme-plan`,
      body: JSON.stringify({
        update_organization_dto: organization,
        billing_address: await dispatch('lteOrganizationToBillwerkCustomerSnakeCase', {
          organization: organization.alternative_billing_address ? organization.alternativeBillingAddressData : organization
        }),
        pricing_plan: organization.pricing_plan
      })
    })
    const responseJson = await response.json()
    const orderId = responseJson.order_id
    const orderCurrency = responseJson.order_currency
    const selfServiceToken = responseJson.self_service_token

    // eslint-disable-next-line no-undef
    const portal = new SubscriptionJS.Portal(selfServiceToken.Token)

    const result = await dispatch('upgradePayInteractive', {
      portal: portal,
      paymentForm: paymentForm,
      order: {
        OrderId: orderId,
        Currency: orderCurrency
      }
    })

    return result
  },

  async finalizeExtendPlan ({ dispatch, commit }, { organizationId }) {
    const response = await dispatch('POST', {
      url: `/rest/organization/${organizationId}/extend-sme-plan/finalize`
    })
    const contractId = await response.text()
    commit('setExtendedContractId', contractId)
  },

  async signupWithInvoice ({ dispatch, getters }, { customer, plan, customerReference, invoiceCustomText }) {
    // eslint-disable-next-line
    const signupService = new SubscriptionJS.Signup()

    const cart = await dispatch('createCart', plan)
    const order = await dispatch('createOrder', {
      signupService,
      cart,
      customer,
      contractCustomFields: {
        CustomerReference: customerReference,
        InvoiceCustomText: invoiceCustomText
      }
    })
    const paymentData = {
      bearer: 'OnAccount:PayOne'
    }

    const payment = await dispatch('createPayment', {
      publicApiKey: getters.billwerkApiKey,
      providerReturnUrl: '' // todo check after payone integration
    })

    return await new Promise((resolve, reject) => signupService.paySignupInteractive(
      payment,
      paymentData,
      order,
      resolve,
      reject)
    )
  },

  async getSelfServiceToken ({ dispatch, getters }, { contractId, handleFailure = true }) {
    contractId = contractId || getters.currentUserOrganization.pricing_plan.contract_id

    if (!contractId) {
      return null
    }

    const response = await dispatch('GET', {
      url: `/rest/billwerk/self-service-token?contract-id=${contractId}`,
      handleFailure: handleFailure
    })

    if (!response.ok) {
      throw new Error(response.status)
    }

    return response.json()
  },

  getContractDetails ({ state }, portal) {
    return new Promise((resolve, reject) => {
      portal.contractDetails(resolve, reject)
    })
  },

  changeCustomerData ({ state }, { portal, customerData }) {
    return new Promise((resolve, reject) => {
      portal.customerChange(customerData, resolve, reject)
    })
  },

  async changeContractInvoiceCustomText ({ state, dispatch }, { contractId, invoiceCustomText }) {
    const token = await dispatch('getSelfServiceToken', { contractId: contractId })
    // eslint-disable-next-line no-undef
    const portal = new SubscriptionJS.Portal(token.Token)

    return dispatch('changeContractCustomFields', { portal: portal, contractCustomFields: { InvoiceCustomText: invoiceCustomText } })
  },

  changeContractCustomFields ({ state }, { portal, contractCustomFields }) {
    return new Promise((resolve, reject) => {
      portal.contractCustomFieldsChange(contractCustomFields, resolve, reject)
    })
  },

  cancelContract ({ state }, portal) {
    return new Promise((resolve, reject) => {
      portal.contractCancel(resolve, reject)
    })
  },

  async createPortalAndCancelContract ({ dispatch }, contractId) {
    const token = await dispatch('getSelfServiceToken', { contractId: contractId })
    // eslint-disable-next-line no-undef
    const portal = new SubscriptionJS.Portal(token.Token)
    return dispatch('cancelContract', portal)
  },

  billwerkToLteUser ({ state }, billwerkCustomer) {
    return {
      name: billwerkCustomer.CompanyName,
      primary_contact_first_name: billwerkCustomer.FirstName,
      primary_contact_last_name: billwerkCustomer.LastName,
      primary_contact_email: billwerkCustomer.EmailAddress,
      street_address: billwerkCustomer.Address?.Street,
      zip_code: billwerkCustomer.Address?.PostalCode,
      city: billwerkCustomer.Address?.City,
      country: billwerkCustomer.Address?.Country
    }
  },

  async getContractCustomerReference ({ dispatch, state }, {organization}) {
    const token = await dispatch('getSelfServiceToken', { contractId: organization.pricing_plan.contract_id })
    // eslint-disable-next-line no-undef
    const portal = new SubscriptionJS.Portal(token.Token)
    const customerData = await dispatch('getContractDetails', portal)
    return customerData.Contract.CustomFields.CustomerReference
  },

  async getExtendedContractEndDate ({ dispatch, state }) {
    const contractId = state.extendedContractId
    const token = await dispatch('getSelfServiceToken', { contractId: contractId })
    // eslint-disable-next-line no-undef
    const portal = new SubscriptionJS.Portal(token.Token)
    const customerData = await dispatch('getContractDetails', portal)
    return customerData.Contract.NextBillingDate
  },

  async triggerPaymentChange ({ dispatch, getters }) {
    await dispatch('POST', {
      url: `/rest/billwerk/update-lawfirm-payment-data/${getters.currentUserOrganization.id}`
    })
  },

  async changePricingPlan ({ dispatch }, { pricingPlan, organizationId }) {
    await dispatch('POST', {
      url: `/rest/billwerk/change-plan/${organizationId}`,
      body: JSON.stringify(pricingPlan)
    })
  },

  async undoContractTermination ({ dispatch }, organizationId) {
    await dispatch('POST', {
      url: `/rest/billwerk/undo-contract-termination/${organizationId}`
    })
  },

  async getPlanPreview ({ dispatch }, organizationId) {
    const response = await dispatch('GET', {
      url: `/rest/billwerk/future-plan-preview/${organizationId}`,
      handleFailure: false
    })

    if (!response.ok) {
      return null
    }

    return await response.json()
  },

  async addOneOffBooking ({ dispatch }, { organizationId, type }) {
    await dispatch('POST', {
      url: `/rest/billwerk/add-one-off-booking/${organizationId}?type=${type === 'trainingVideo' ? 'TRAINING_VIDEO' : 'TRAINING_HOUR'}`
    })
  }

}
