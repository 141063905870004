import dayjs from "@/plugins/day"

export default {
  setShowTranslateDialog (state, value) {
    state.showTranslateDialog = value
  },

  setCurrentReportNumber (state, reportNumber) {
    state.currentReportNumber = reportNumber
  },

  setCurrentReportId (state, reportId) {
    state.currentReportId = reportId
  },

  setCurrentReport (state, report) {
    state.currentReport = report
    state.currentReportId = report.id
    state.currentReportNumber = report.report_number
  },

  loadReportTranslations (state, statementsTranslations) {
    state.currentReport.statements_translations = statementsTranslations
  },

  setReports (state, reports) {
    state.reports = reports
  },

  setChatMessages (state, chatMessages) {
    state.currentReport.chat_messages = chatMessages
  },

  setCurrentReportFiles (state, files) {
    state.currentReport.uploaded_files = files
  },

  setInternalNotes (state, notes) {
    // don't override the array to keep current update status for notes that are opened atm
    state.currentReport.internal_notes = notes.map(note => {
      const currentNote = state.currentReport.internal_notes?.find(_note => _note.id === note.id)
      if (currentNote?.edit) {
        return currentNote
      }

      return note
    })
  },

  setReportStatus (state, status) {
    state.currentReport.status = status
  },

  setReportsTableSearch (state, search) {
    state.reportsTableOptions.search = search
  },

  toggleReportFilter (state, { filter, value }) {
    console.log(filter, value)
    const isPresent = state.reportsTableOptions.filter[filter].includes(value)
    console.log(state.reportsTableOptions.filter[filter])
    if (isPresent) {
      state.reportsTableOptions.filter[filter] = state.reportsTableOptions.filter[filter].filter(_value => _value !== value)
    } else {
      state.reportsTableOptions.filter[filter] = [...state.reportsTableOptions.filter[filter], value]

    }
  },

  setReportDateFilter (state, dates) {
    state.reportsTableOptions.filter.date = dates.map(date => dayjs(date).format('YYYY-MM-DD'))
  },

  setAllReportTableVuetifyOptions (state, options) {
    // the sortable options has to have a length of 1 or 2 long at all times and one element always has to be 'updated'
    const index = options.sortBy.indexOf('updated')
    // when sorting after 'updated', it should only be sorted after updated
    if (state.reportsTableOptions.vuetifyOptions.sortDesc[index] !== options.sortDesc[index] && options.sortBy.length > 1) {
      options.sortBy = ['updated']
      options.sortDesc = [state.reportsTableOptions.vuetifyOptions.sortDesc[index]]
      // when the user clicks on another column to sort, the initial sort will be removed and the new one added
    } else if (options.sortBy.length === 3) {
      options.sortBy[0] = options.sortBy[2]
      options.sortBy[1] = 'updated'
      options.sortDesc[1] = true
      options.sortBy.pop()
      options.sortDesc.pop()
      // updated should always be second in sorting
    } else if (options.sortBy.length === 2 && options.sortBy[0] === 'updated') {
      options.sortBy = options.sortBy.reverse()
      options.sortDesc = options.sortDesc.reverse()
    }

    state.reportsTableOptions.vuetifyOptions = options
  },

  setReportTableVuetifyOptions (state, { field, value }) {
    state.reportsTableOptions.vuetifyOptions[field] = value
  },

  setCaseManagerOptions (state, caseManagerOptions) {
    state.reportsTableOptions.caseManagerOptions = caseManagerOptions
  },

  setCategoryOptions (state, categoryOptions) {
    state.reportsTableOptions.categoryOptions = categoryOptions
  },

  setFollowUpActionOptions (state, followUpActionOptions) {
    state.reportsTableOptions.followUpActionOptions = followUpActionOptions
  },

  setReportFollowUpActions (state, reportFollowUpActions) {
    state.currentReport.report_follow_up_actions = reportFollowUpActions
  },

  setReportPermissions (state, reportPermissions) {
    state.currentReport.report_permissions = reportPermissions
  },

  setCategory (state, categoryId) {
    state.currentReport.category_id = categoryId
  },

  setCountry (state, country) {
    state.currentReport.country = country
  },

  setCategoryLawType (state, categoryLawType) {
    state.currentReport.category_law_type = categoryLawType
  },

  setCaseManager (state, userId) {
    state.currentReport.case_manager = { id: userId }
  },

  addCaseReader (state, userId) {
    if (!state.currentReport.case_readers) {
      state.currentReport.case_readers = [{ case_readers_id: userId }]
    } else {
      state.currentReport.case_readers = [...state.currentReport.case_readers, { case_readers_id: userId }]
    }
  },

  removeCaseReader (state, userId) {
    state.currentReport.case_readers = state.currentReport.case_readers.filter(user => user.case_readers_id !== userId)
  },

  setCategories (state, categories) {
    state.categories = categories
  },

  setFollowUpActions (state, followUpActions) {
    state.followUpActions = followUpActions
  },

  markReportAsReadUnread (state, { reportId, isRead }) {
    if (reportId === state.currentReportId) {
      state.currentReport.is_read = isRead
    }
    state.reports = state.reports.map(r => r.id === reportId ? { ...r, is_read: isRead } : r)
  },

  setRequiresPinChange (state, requiresPinChange) {
    state.currentReport.requires_pin_change = requiresPinChange
  },

  setWhistleblowerEmail (state, email) {
    state.currentReport.whistleblower_email = email
  },
  setSelectedReports (state, selectedReports) {
    state.selectedReports = selectedReports
  }
}
