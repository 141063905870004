import apollo from '../../apollo'
import gql from 'graphql-tag'
import router from '../../router'
import { organization, organizationConfiguration } from '@/store/graphqlFragments'
import vuetify from '@/plugins/vuetify'
import { REPORT_NUMBER_PATTERN, UUID_PATTERN } from '@/constants'
import i18next from 'i18next'

function checkOrganizationId (userData, isLteAdmin, isCallOperator) {
  if (router.currentRoute.value.params.companyHash && userData && userData.organization_id) {
    if (router.currentRoute.value.params.companyHash !== userData.organization_id) {
      const newQuery = JSON.parse(JSON.stringify(router.currentRoute.value.query))

      if (newQuery.redirectTo) {
        const redirectToArray = newQuery.redirectTo.split("/");
        if (redirectToArray[1]  === router.currentRoute.value.params.companyHash) {
          redirectToArray[1] = userData.organization_id
          newQuery.redirectTo = redirectToArray.join("/")
        }
      }

      router.replace({
        name: router.currentRoute.value.name,
        query: newQuery,
        params: {...newQuery, companyHash: userData.organization_id}
      })
    }
  } else if (router.currentRoute.value.params.companyHash && isLteAdmin) {
    if (router.currentRoute.value.params.companyHash !== 'admin') {
      const newQuery = JSON.parse(JSON.stringify(router.currentRoute.value.query))

      if (newQuery.redirectTo) {
        const redirectToArray = newQuery.redirectTo.split("/");
        if (redirectToArray[1]  === router.currentRoute.value.params.companyHash) {
          redirectToArray[1] = 'admin'
          newQuery.redirectTo = redirectToArray.join("/")
        }
      }

      router.replace({
        name: router.currentRoute.value.name,
        query: newQuery,
        params: {...newQuery, companyHash: 'admin'}
      })
    }
  }
}

export default {

  async login ({ state, commit, dispatch, getters }, { email, password, accessToken }) {
    const authorization = accessToken ? 'Bearer ' + accessToken : 'Basic ' + btoa(`${email.toLowerCase()}:${password}`)
    const request = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization
      },
      method: 'POST'
    }
    try {
      const response = await fetch('/rest/auth/login', request)
      if (response.ok) {
        const responseJson = await response.json()
        if (responseJson.requiresMfaVerification) {
          commit('setAuthToken', responseJson.session)
          response.requiresMfaVerification = responseJson.requiresMfaVerification
          response.mfaMethod = responseJson.mfaMethod
        } else {
          dispatch('handleLogin', { responseJson })
        }
      }
      return response
    } catch (error) {
      commit('showDefaultError')
      throw error
    }
  },

  handleSessionExpired ({ commit }) {
    const opener = window.opener
    if (opener && router.currentRoute.value.query.sessionExpired && [
      'https://app-test.whistle-report.com',
      'https://demo.whistle-report.com',
      'https://app.whistle-report.com',
      'https://lte-dev.azurewebsites.net',
      'http://localhost:8081',
      'http://localhost:8082'].includes(opener.origin)) {
      window.opener.postMessage('LTE_LOGIN_RESTORED', '*')
      window.close()
    }
  },

  async handleLogin ({ dispatch, getters, commit, state }, { responseJson }) {
    await dispatch('handleSessionExpired')

    commit('setUserData', responseJson.userDetails)

    checkOrganizationId(responseJson.userDetails, getters.isLteAdmin, getters.isCallOperator)

    commit('setAuthToken', responseJson.session)

    await dispatch('loadOrganizationConfiguration')

    if (responseJson.userDetails && !getters.isGlobalUser && !getters.isSupport) {
      dispatch('subscribeToNotifications')
    }
    if (!getters.isCallOperator) {
      await dispatch('loadAllUsers')
      await dispatch('loadOrganizations')
      if (!getters.isLteAdmin) {
        await dispatch('loadCategories')
      }
      if (!getters.isLteAdmin && getters.isOrganizationAdmin) {
        const expired = await dispatch('doesPricingPlanExpire', true)
        commit('setPlanExpires', expired)
      }

      const currentOrganizationId = state.currentOrganizationId || state.userData?.organization_id
      const signInMethodExternal = getters.organizationHasSignInMethodExternal(currentOrganizationId)

      if (responseJson.userDetails.show_welcome_popup && (!signInMethodExternal && !responseJson.userDetails.requires_password_change)) {
        commit('setShowWelcomePopup', true)
      }
    }

    await dispatch('loadCurrentLanguage')
  },

  async loginMfa ({ dispatch }, { code, verifyTempMfa }) {
    const response = await dispatch('POST', {
      url: '/rest/auth/verify-mfa?verifyTempMfa=' + verifyTempMfa,
      body: JSON.stringify({
        code: code
      }),
      handleFailure: false
    })

    if (response.ok && !verifyTempMfa) {
      const responseJson = await response.json()
      dispatch('handleLogin', { responseJson })
    }

    return response
  },

  async loadRoles ({ dispatch, state, commit }) {
    const response = await dispatch('GET', {
      url: '/rest/auth/roles'
    })
    if (response.ok) {
      const roles = await response.json()
      commit('setRoles', roles)
    }
  },

  async logout ({ state, dispatch, getters }) {
    if (window.onbeforeunload) {
      if (!confirm(i18next.t('general.changesNotSaved'))) {
        return
      }
      // Remove warning popup
      window.onbeforeunload = null
    }

    const wasWHB = getters.isWhb
    await dispatch('POST', {
      url: '/rest/auth/logout'
    })
    if (wasWHB) {
      window.location.pathname = `/report/${router.currentRoute.value.params.companyHash}`
    }  else if (state.sessionExpired) {
      window.location.href = router.currentRoute.value.params?.companyHash && router.currentRoute.value.params?.companyHash !== 'admin'
        ? `/${router.currentRoute.value.params.companyHash}/login?sessionExpired=1` : '/login?sessionExpired=1'
    } else {
      window.location.pathname = router.currentRoute.value.params?.companyHash && router.currentRoute.value.params?.companyHash !== 'admin' ? `/${router.currentRoute.value.params.companyHash}/login` : '/'
    }
  },

  async refreshSession ({ dispatch }) {
    return await dispatch('GET', {
      url: '/rest/auth/refresh-session'
    })
  },

  /**
   * Simply keeps the current session alive
   */
  async keepLoginAlive ({ state, commit, dispatch }) {
    // logged-in user session has authToken & userData,
    // whb session has authToken only
    if (state.authToken && state.userData) {
      console.log('keeping login session alive')
      const response = await dispatch('refreshSession')
      if (response.status === 401) {
        console.log('session expired')
        commit('setSessionExpired', true)
      } else {
        commit('setSessionExpiredTimeoutId')
      }
    } else {
      console.log('not logged in yet, skipping keep-alive')
    }
  },

  async refreshUserData ({ commit, state }) {
    const response = await fetch('/rest/auth/current-user', { headers: { 'x-auth-token': state.authToken } })
    if (response.status === 200) {
      const json = await response.json()
      commit('setUserData', json.userDetails)
      commit('setAuthToken', json.session)
    }
  },

  async loadUserData ({ commit, state, dispatch, getters }, { sessionExpired = false, setAccounts = true } = {}) {
    if (!state.authToken || sessionExpired) {
      // restore token from cookie
      const response = await fetch('/rest/auth/restore-token-from-cookie')
      if (response.status === 200) {
        const json = await response.json()
        if (json.session) {
          commit('setAuthToken', json.session)
        }
      } else {
        console.log('restoring session token failed')
      }

      if (sessionExpired) {
        return
      }
    }
    const response = await fetch('/rest/auth/current-user', { headers: { 'x-auth-token': state.authToken } })
    if (response.status === 200) {
      const json = await response.json()
      commit('setUserData', json.userDetails)
      checkOrganizationId(json.userDetails, getters.isLteAdmin, getters.isCallOperator)
      commit('setAuthToken', json.session)
      if (json.userDetails) {
        await dispatch('loadOrganizationConfiguration')
        if (state.userData && !getters.isGlobalUser && !getters.isSupport) {
          dispatch('subscribeToNotifications')
        }
        if (!getters.isCallOperator) {
          await dispatch('loadAllUsers')
          if (setAccounts) {
            await dispatch('loadOrganizations')
          } else {
            await dispatch('loadOrganizations', { setAccounts: false })
          }
          if (!getters.isLteAdmin) {
            await dispatch('loadCategories')
          }
        }
        if (getters.currentUserOrganization && !json.userDetails.setup_completed) {
          if (getters.isOrganizationSetup) {
            router.push({ name: 'SetupLanguageReset', params: { companyHash: json.userDetails.organization_id } })
          } else if (json.userDetails.requires_password_change) {
            router.replace({
              path: `/${json.userDetails.organization_id || 'admin'}/password-reset`
            })
          } else {
            router.push({ name: 'SetupLanguageReset', params: { companyHash: json.userDetails.organization_id } })
          }
        } else if (getters.isOrganizationSetupWithBranding) {
          if (json.userDetails.requires_password_change) {
            router.push({ name: 'SetupPasswordReset', params: { companyHash: json.userDetails.organization_id } })
          } else {
            router.push({
              name: 'SetupBranding',
              params: { companyHash: json.userDetails.organization_id }
            }).catch(() => router.push({
              name: 'SetupBranding',
              params: { companyHash: json.userDetails.organization_id }
            }))
          }
        } else if (!json.userDetails.setup_completed) {
          router.push({ name: 'SetupLanguageReset', params: { companyHash: json.userDetails.organization_id } })
        } else if (json.userDetails.requires_password_change) {
          router.replace({
            path: `/${json.userDetails.organization_id || 'admin'}/password-reset`
          })
        }
        if (getters.isTranslator) {
          router.replace({ name: 'TranslationEditor', params: { companyHash: 'admin' } })
        }
      }
    } else {
      console.error('fetching current user data failed')
      console.error(response)
    }
  },

  async doesPricingPlanExpire ({ state, getters, dispatch, commit }, isPopUp = false) {
    if (getters.isLteAdmin || getters.isTranslator || getters.isCallOperator) {
      return false
    }
    // eslint-disable-next-line
    const contractId = getters.currentUserOrganization?.pricing_plan?.contract_id
    if (!contractId) {
      return
    }

    let token
    try {
      token = await dispatch('getSelfServiceToken', { contractId: contractId, handleFailure: false })
    } catch (error) {
      commit('showDefaultError')
      return
    }

    if (token) {
      // eslint-disable-next-line
      const portal = new SubscriptionJS.Portal(token.Token)
      const customerData = await dispatch('getContractDetails', portal)
      if (customerData?.Contract?.EndDate) {
        const endDate = Date.parse(customerData.Contract.EndDate)
        // 7 days before the contract runs out the pop-up should show
        if (isPopUp && new Date(endDate - 7 * 24 * 60 * 60 * 1000) < Date.now() && endDate > Date.now()){
          commit('setPricingPlanExpires', true)
          return true
        }
      }
    }

    commit('setPricingPlanExpires', false)
    return false
  },
  /**
   *
   * language process:
   * logged in = userLanguage->urlParamLanguage->browserLanguage->english->german
   * not logged in = urlParamLanguage->browserLanguage->english->german
   */
  async loadCurrentLanguage ({ state, getters, dispatch }) {
    const currentUserOrganization = getters.currentUserOrganization
    if (state.userData && state.userData.language) {
      let organizationLanguages
      if (currentUserOrganization) {
        organizationLanguages = currentUserOrganization.organization_languages.map(language => language.language_locale)
      }
      if ((organizationLanguages && organizationLanguages.includes(state.userData.language)) || getters.isLteAdmin) {
        dispatch('changeLanguage', state.userData.language)
        return
      }
    }
    const urlParamLanguage = router.currentRoute.value.query.language
    if (urlParamLanguage) {
      await dispatch('changeLanguage', urlParamLanguage)
    } else {
      let browserLanguage = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
      browserLanguage = browserLanguage ? browserLanguage.split('-')[0] : browserLanguage
      await dispatch('changeLanguage', browserLanguage)
    }
  },

  async changeLanguage ({ state, commit, getters }, destinationLanguage) {
    if (destinationLanguage === 'nb') {
      destinationLanguage = 'no'
    }
    if (state.organizations.length > 0) {
      let organizationLanguages = state.organizations[0].organization_languages
      if (getters.currentUserOrganization) {
        organizationLanguages = getters.currentUserOrganization.organization_languages
      } else if (getters.isLteAdmin) {
        const languageOptions = []
        state.languages.forEach(language => languageOptions.push({ language_locale: language.locale }))
        organizationLanguages = languageOptions
        // organizationLanguages = state.languages.map(language => { language_locale : language.locale })
      }
      if (organizationLanguages.find(languageOption => languageOption.language_locale === destinationLanguage)) {
        await i18next.changeLanguage(destinationLanguage)
        vuetify.locale.current = destinationLanguage
      } else {
        if (organizationLanguages.find(languageOption => languageOption.language_locale === 'en')) {
          await i18next.changeLanguage('en')
          vuetify.locale.current = 'en'
        } else if (organizationLanguages.find(languageOption => languageOption.language_locale === 'de')) {
          await i18next.changeLanguage('de')
          vuetify.locale.current = 'de'
        }
      }
    } else {
      if (state.languages.find(language => language.locale === destinationLanguage)) {
        await i18next.changeLanguage(destinationLanguage)
        vuetify.locale.current = destinationLanguage
      }
    }
    const currentLanguage = vuetify.locale.current
    commit('setLanguage', currentLanguage)
  },

  async loadOrganizationConfiguration ({ state, commit, dispatch, getters }) {
    const response = await apollo(state).query({
      query: gql`
        query organization_configuration($lteUserId: uuid) {
          lte_user (where: {id: {_eq: $lteUserId}})  {
            ${organizationConfiguration}
          }
        }
      `,
      variables: {
        lteUserId: state.userData.id
      }
    })
    commit('setOrganizationConfiguration', response.data.lte_user[0].organization_configuration)
  },

  async loadOrganizations ({ state, commit, dispatch, getters }, { setAccounts = true } = {}) {
    if (getters.isTranslator) {
      console.warn('Translators should not attempt loading organizations')
      return
    }
    const response = await apollo(state).query({
      query: gql`
        query organization {
          ${organization(getters.isWhb || getters.isCallOperator, getters.isOrganizationAdminOrSupport, getters.isLteAdmin)}
        }
      `
    })

    if (state.userData) {
      response.data.organization.map(el => {
        if (!el.active) {
          el.status = 'disabled'
        } else if (el.suspended) {
          el.status = 'suspended'
        } else {
          el.status = 'active'
        }
      })
    }

    if (getters.isWhb || getters.isCallOperator) {
      await dispatch('loadCategories')
      response.data.organization.forEach(organization => {
        const organizationCategories = state.categories.filter(category => category.organization_id === organization.id)
        organization.report_categories = organizationCategories
      })
    }

    commit('setOrganizations', response.data.organization)

    // Filter parent organizations
    const parentOrganizations = []
    // eslint-disable-next-line camelcase
    const currentOrgId = state.currentOrganizationId || state.userData?.organization_id
    if (currentOrgId) {
      // eslint-disable-next-line camelcase
      let parentId = response.data.organization.find(organization => organization.id === currentOrgId)?.parent_id
      while (parentId) {
        const parent = response.data.organization.find(organization => organization.id === parentId)
        parentOrganizations.push(parent)

        // eslint-disable-next-line camelcase
        parentId = parent?.parent_id
      }
    }
    // Filter ownAndChild organizations
    const ownAndChildOrganizations = response.data.organization.filter((el) => !parentOrganizations.includes(el))

    commit('setParentOrganizations', parentOrganizations)
    commit('setOwnAndChildOrganizations', ownAndChildOrganizations)

    if ((getters.isWhb || getters.isCallOperator) && response.data.organization.length && router.currentRoute.value.name !== 'Organizations') {
      // await $vue.$i18n.i18next.changeLanguage(response.data.organization[0].default_language)
    }

    if (state.userData) {
      commit('setLawfirms', response.data.organization.filter(el => el.dtype === 'LawFirm'))
    }

    if (setAccounts) {
      let accounts = state.ownAndChildOrganizations.map(organization => organization.id).sort()
      if (!getters.hasRoleForOwnOrganization(['ADMIN', 'SUPPORT'])) {
        accounts = state.ownAndChildOrganizations
          .filter(organization => getters.hasRoleForOrganization(organization.id, ['ADMIN', 'GENERAL_CASE_READER', 'GENERAL_CASE_MANAGER', 'SUPPORT', 'USER', 'REPORTER']) ||
            getters.hasRoleForOrganizationOrParentOrganization(organization.id, ['ADMIN']))
          .map(organization => organization.id)
          .sort()
      }
      if (currentOrgId && accounts.includes(currentOrgId)) {
        accounts.splice(accounts.indexOf(currentOrgId), 1)
        accounts.unshift(currentOrgId)
      }
      commit('setAccounts', accounts)
    }

    let allOrganizationLocales = response.data.organization
      .map(org => org.organization_languages)
      .reduce((pre, cur) =>  pre.concat(cur), [])
      .map(lang => lang.language_locale)
    allOrganizationLocales = [...new Set(allOrganizationLocales)];

    dispatch('loadLanguage', { locales: allOrganizationLocales })

    dispatch('updateColorCodes')
    dispatch('changeFavicon')

    if (getters.canNavigateDashboard) {
      dispatch('loadMetrics')
    }
  },

  async addCurrentOrganizationToSession ({ dispatch, state, commit, getters }) {
    if (state.userData && !getters.isCallOperator) {
      return
    }
    if (!UUID_PATTERN.test(state.currentOrganizationId)) {
      console.log('Invalid organization identifier')
      commit('setShow404Page', true)
      return
    }

    const response = await dispatch('POST', {
      url: `/rest/auth/whistleblower/add-organization-to-session?organizationId=${state.currentOrganizationId}`,
      handleFailure: false
    })

    if (!response.ok) {
      commit('setShow404Page', true)
      return
    }

    await dispatch('loadOrganizations')
    if (!getters.isLteAdmin) {
      await dispatch('loadCategories')
    }
  },

  async addReportToSession ({ dispatch, state, commit, getters }, reportNumber) {
    const localReportNumber = reportNumber || state.currentReportNumber
    if (!REPORT_NUMBER_PATTERN.test(localReportNumber)) {
      console.log('Invalid report number')
      if (!getters.isWhb && !getters.isCallOperator) {
        router.push({ name: 'SMEReports' })
      } else {
        commit('setShow404Page', true)
      }
      return
    }
    return await dispatch('POST', {
      url: `/rest/auth/whistleblower/add-report-to-session?reportNumber=${localReportNumber}`,
      handleFailure: false
    })
  },

  async unlockReport ({ dispatch, state }, pin) {
    const response = await dispatch('POST', {
      url: '/rest/auth/whistleblower/unlock-report',
      body: JSON.stringify({
        pin: pin,
        reportNumber: state.currentReportNumber,
      }),
      handleFailure: false
    })

    if (response.status !== 200) {
      return response
    }

    const reportId = await response.text()
    return reportId.slice(1, reportId.length - 1)
  }

}
