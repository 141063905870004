import {SIGN_IN_METHOD} from "../../constants";

export default {

  /**
   * basicUserType accepts either a string or an array of strings
   */
  hasBasicUserType: (state) => (basicUserType) => {
    if (!Array.isArray(basicUserType)) {
      basicUserType = [basicUserType]
    }
    // eslint-disable-next-line camelcase
    return basicUserType.includes(state.userData?.basic_user_type)
  },

  /**
   * role accepts either a string or an array of strings
   */
  hasRoleForOrganization: (state, getters) => (organizationId, role) => {
    return _hasRoleForOrganization(getters.currentUser, organizationId, role)
  },

  hasRoleForOrganizationOrParentOrganization: (state, getters) => (organizationId, role) => {
    return _hasRoleForOrganizationOrParentOrganization(getters.currentUser, organizationId, role)
  },

  /**
   * role accepts either a string or an array of strings
   */
  hasRoleForAnyOrganization: (state, getters) => (role) => {
    if (!Array.isArray(role)) {
      role = [role]
    }
    // eslint-disable-next-line camelcase
    return getters.currentUser?.role_assignments?.some(assignment => role.includes(assignment.role))
  },

  /**
   * role accepts either a string or an array of strings
   */
  hasRoleForOwnOrganization: (state, getters) => (role) => {
    // eslint-disable-next-line camelcase
    return getters.hasRoleForOrganization(state.userData?.organization_id, role)
  },

  hasRoleForTopOrganization: (state, getters) => (role) => {
    // eslint-disable-next-line camelcase
    return getters.hasRoleForOrganization(getters.accountsFilterArray[0], role)
  },

  hasRoleForTopOrganizationOrParentOrganization: (state, getters) => (role) => {
    // eslint-disable-next-line camelcase
    return getters.hasRoleForOrganizationOrParentOrganization(getters.accountsFilterArray[0], role)
  },

  isWhb (state) {
    return !state.userData
  },

  isOrganizationAdmin (state, getters) {
    return getters.hasRoleForAnyOrganization('ADMIN')
  },
  isOrganizationAdminOrSupport (state, getters) { // expected to also pass for the LTE ADMIN TODO refactor this
    return getters.isLteAdmin || getters.hasRoleForAnyOrganization(['ADMIN', 'SUPPORT'])
  },
  isOwnOrganizationAdminOrSupport (state, getters) {
    return getters.isLteAdmin || getters.hasRoleForOwnOrganization(['ADMIN', 'SUPPORT'])
  },
  isTopOrganizationAdminOrSupport (state, getters) {
    return getters.isLteAdmin || getters.hasRoleForTopOrganization(['ADMIN', 'SUPPORT'])
  },
  isTopOrganizationOrParentOrganizationAdminOrSupport (state, getters) {
    return getters.isLteAdmin || getters.hasRoleForTopOrganizationOrParentOrganization(['ADMIN', 'SUPPORT'])
  },

  /*
    Return true if there IS NO OTHER ADMIN or GENERAL CASE MANAGER and false otherwiese
   */
  noOtherAdminOrGeneralCaseManagerInCurrentReportOrganization (state, getters) {
    if (!state.currentReport) {
      return true
    }

    const organizationId = state.currentReport.organization_id
    const usersWithRole = state.users.filter(user => _hasRoleForOrganization(user, organizationId, ['ADMIN', 'GENERAL_CASE_MANAGER']))

    if (usersWithRole.length < 1) {
      return true
    }

    if (usersWithRole.length > 1) {
      return false
    }

    return usersWithRole[0].id === getters.currentUser.id
  },

  /**
   * Whether the user has case manager rights for the current report
   */
  isCaseManager (state, getters) {
    return state.currentReport && state.userData &&
    /* eslint-disable camelcase */
      (getters.hasRoleForOrganization(state.currentReport?.organization_id, ['ADMIN', 'GENERAL_CASE_MANAGER']) ||
        (state.currentReport?.case_manager?.id === state.userData.id))
    /* eslint-enable camelcase */
  },

  isAdminOrGeneralCaseManagerForCurrentReport (state, getters) {
    return state.currentReport && state.userData &&
      /* eslint-disable camelcase */
      getters.hasRoleForOrganization(state.currentReport?.organization_id, ['ADMIN', 'GENERAL_CASE_MANAGER'])
    /* eslint-enable camelcase */
  },

  isGeneralCaseManager (state, getters) {
    return getters.hasRoleForAnyOrganization(['GENERAL_CASE_MANAGER'])
  },

  /**
   * Whether the user has case reader rights for the current report
   */
  isCaseReader (state, getters) {
    return state.currentReport && state.userData &&
      /* eslint-disable camelcase */
      (getters.hasRoleForOrganization(state.currentReport?.organization_id, ['GENERAL_CASE_READER']) ||
        (state.currentReport?.case_readers.filter(item => item.case_readers_id === state.userData.id)))
    /* eslint-enable camelcase */
  },

  isLteAdmin (state, getters) {
    return getters.hasBasicUserType('LTE_ADMIN')
  },

  isTranslator (state, getters) {
    return getters.hasBasicUserType('TRANSLATOR')
  },

  isCallOperator (state, getters) {
    return getters.hasBasicUserType('CALL_OPERATOR')
  },

  isSupport (state, getters) {
    return getters.hasRoleForAnyOrganization('SUPPORT')
  },

  isUser (state, getters) {
    return getters.hasRoleForAnyOrganization('USER')
  },

  isGlobalUser (state, getters) {
    return getters.isLteAdmin || getters.isCallOperator || getters.isTranslator
  },

  isLawFirm (state, getters) {
    return getters.hasBasicUserType('LAW')
  },

  isNationalSalesPartner (state, getters) {
    return getters.hasBasicUserType('NSP')
  },

  isRootOrganization (state, getters) {
    const currentUserOrganization = getters.currentUserOrganization
    return getters.isRootPartner || (getters.hasBasicUserType('SME') && currentUserOrganization && !currentUserOrganization.parent_id)
  },

  isRootPartner (state, getters) {
    const currentUserOrganization = getters.currentUserOrganization
    return getters.hasBasicUserType('NSP') || (getters.hasBasicUserType('LAW') && currentUserOrganization && !currentUserOrganization.parent_id)
  },

  isEndOrganization (state, getters) {
    return getters.hasBasicUserType('SUB')
  },

  isSubsidiary (state, getters) {
    return getters.hasBasicUserType('SUB')
    // return getters.selectedOrganization ? getters.selectedOrganization.dType === 'Subsidiary' : getters.ownOrganization?.dType === 'Subsidiary'
  },

  isWhiteLabel (state, getters) {
    return state.userData.white_label
  },

  isImpersonated (state) {
    return typeof state.accountsFilter === 'string' && state.accountsFilter !== state.userData.organization_id
  },

  accountSelected (state) {
    return typeof state.accountsFilter === 'string'
  },

  accountsFilterArray (state) {
    if (Array.isArray(state.accountsFilter)) {
      return state.accountsFilter
    }
    if (typeof state.accountsFilter === 'string') {
      return [state.accountsFilter]
    }
    return []
  },

  disableBillwerk () {
    return ['demo.whistle-report.com', 'demo-internal.whistle-report.com'].includes(window.location.hostname) || !!process.env.JEST_WORKER_ID
  },

  isCurrentUserOrganizationBillwerkable (state, getters) {
    const organization = getters.currentUserOrganization
    return getters.isOrganizationBillwerkable(organization)
  },

  isOrganizationBillwerkable: (state, getters) => (organization) => {
    if (getters.disableBillwerk) {
      return false
    }

    if (organization && organization.sign_in_method === SIGN_IN_METHOD.TNM_MICROSOFT) {
      return false
    }

    return true

  }

}

/**
 * role accepts either a string constant or an array of strings
 */
export function _hasRoleForOrganization (lteUser, organizationId, role) {
  if (!Array.isArray(role)) {
    role = [role]
  }
  // eslint-disable-next-line camelcase
  if (!lteUser?.role_assignments) {
    return false
  }

  return lteUser.role_assignments.some(assignment => role.includes(assignment.role) && assignment.organization_id === organizationId)
}

export function _hasRoleForOrganizationOrParentOrganization (lteUser, organizationId, role) {
  if (!Array.isArray(role)) {
    role = [role]
  }
  // eslint-disable-next-line camelcase
  if (!lteUser?.role_assignments) {
    return false
  }

  return lteUser.role_assignments.some(assignment => role.includes(assignment.role) &&
    (assignment.organization_id === organizationId || assignment.organization.child_organizations.some(org => org.organization_id === organizationId)))
}

export function _hasRoleForOrganizationOrChildOrganization (lteUser, organizationId, role) {
  if (!Array.isArray(role)) {
    role = [role]
  }
  // eslint-disable-next-line camelcase
  if (!lteUser?.role_assignments) {
    return false
  }

  return lteUser.role_assignments.some(assignment => role.includes(assignment.role) &&
    (assignment.organization_id === organizationId || assignment.organization.parent_organizations.some(org => org.organization_id === organizationId)))
}
