import shortid from 'shortid'

/**
 *
 * @param overrideFields an object that will be merged into the result
 * @returns {{employee_count: number, street_address: string, country: string, primary_contact_email: string, pricing_plan: {languages: number, pricing_plan_template: null, name: string, custom_questionnaire_allowed: boolean, user_accounts: number, employees: number, branding_allowed: boolean, yearly_price: number}, primary_contact_last_name: string, city: string, registration_number: string, active: boolean, dtype: string, primary_contact_first_name: string, zip_code: string, parent_id: string, name: string, vat_id: string}}
 */
export function newOrganization (overrideFields) {
  const generatedId = shortid.generate().toLowerCase()
  const parent = overrideFields && overrideFields.parent ? {
    dtype: overrideFields.parent.dtype,
    id: overrideFields.parent.id,
    name: overrideFields.parent.name,
    parent_id: overrideFields.parent.id
  } : null
  return {
    active: true,
    name: `GenericTestOrganization-${generatedId}`,
    dtype: 'Sme',
    parent_id: '',
    employee_count: 30000,
    vat_id: 'DE3123123',
    registration_number: '4213432',
    street_address: 'Bahnhofstraße 7',
    city: 'Frankurt am Main',
    zip_code: '60320',
    country: 'DE',
    default_language: 'de',
    primary_contact_first_name: 'Primary',
    primary_contact_last_name: 'Contact',
    primary_contact_email: `primary-contact-${generatedId}@test.pt.software`,
    pricing_plan: {
      branding_allowed: true,
      custom_questionnaire_allowed: true,
      employees: 30000,
      languages: 2,
      name: 'Enterprise 2000',
      user_accounts: 10
    },
    ...overrideFields,
    parent
  }
}

export function newReport (overrideFields) {
  return {
    information: 'Sample Report',
    location: 'Home',
    date: '02.07.2020',
    involvedPersons: 'Me, Boss',
    relationshipToCompany: 'yesNo.YES',
    personRelationshipToCompany: 'whbRelationToCompany.PARTNER',
    additionalInformation: 'Sample additional info',
    ...overrideFields
  }
}

export function newUser (overrideFields) {
  const generatedId = shortid.generate().toLowerCase()
  return {
    active: 'true',
    basic_user_type: 'LTE',
    // organization_id: ...,
    role: 'LTE_ADMIN',
    title: 'Dr.',
    password: 'lte2020',
    first_name: 'Generated John',
    last_name: `Doe ${generatedId}`,
    email: `testuser-${generatedId}@test.pt.software`,
    position: 'Partner',
    ...overrideFields
  }
}
