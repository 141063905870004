import { _hasRoleForOrganization } from '@/store/getters/authorizationGetters'
import i18next from "i18next";

export default {
  caseReaderOptions (state, getters) {
    return state.users.filter(user =>
      // eslint-disable-next-line camelcase
      _hasRoleForOrganization(user, state.currentReport?.organization_id, 'USER'))
  },

  caseManagerOptions (state, getters) {
    return state.users.filter(user =>
      // eslint-disable-next-line camelcase
      _hasRoleForOrganization(user, state.currentReport?.organization_id, ['ADMIN', 'GENERAL_CASE_MANAGER', 'GENERAL_CASE_READER', 'USER']))
  },

  languageOptionsOfOrganization: (state, getters) => (organization) => {
    organization = organization || getters.currentUserOrganization || state.organizations[0]
    if (!organization || !organization.organization_languages) {
      return []
    }

    return organization.organization_languages.map(language => ({
      slug: language.language_locale,
      label: i18next.t(`languages.${language.language_locale}`)
    })).sort((a, b) => a.slug < b.slug ? -1 : 1)
  },

  accessibleOrganizations: (state, getters) => {
    return state.organizations
  },

  /**
   * this function can take single value or array of organization objects or organization id as string and returns if the current user
   * can manage organization role.
   *
   * it returns single object if single value is passed or returns array of object if array of organization is passed
   * return object contains information about what kind of access the person has and which kind of roles this person can assign
   */
  canManageOrganizationRole: (state, getters) => (organization) => {
    const requiredRoleMap = {
      ADMIN: ['ADMIN'],
      GENERAL_CASE_MANAGER: ['ADMIN'],
      GENERAL_CASE_READER: ['ADMIN'],
      SUPPORT: ['ADMIN', 'SUPPORT'],
      USER: ['ADMIN', 'SUPPORT'],
      REPORTER: ['ADMIN', 'SUPPORT']
    }

    const canManageRole = {}
    Object.keys(requiredRoleMap).forEach(key => {
      const vals = requiredRoleMap[key]
      if (vals) {
        vals.forEach(val => {
          if (!canManageRole[val])canManageRole[val] = []
          canManageRole[val].push(key)
        })
      }
    })

    const isArray = Array.isArray(organization)
    // find all the organization objects
    if (organization === undefined || organization === null) return
    if (!isArray) organization = [organization]
    else if (organization.length === 0) return [] // return empty array if empty array was passed as argument
    organization = organization.map(org => state.organizations.find(stateOrg => stateOrg.id === ((typeof org === 'string' && org) || org.id))).filter(x => x)
    if (organization.length === 0) throw new Error('organization must be either the org_id or must have a property id or array of the same')

    const ret = organization.map(org => {
      // current user has admin or support role in the organization
      const canDirectlyEditRoles = org.role_assignments?.find(assignment => assignment.lte_user?.id === state.userData.id && ['ADMIN', 'SUPPORT'].indexOf(assignment.role) >= 0)
      let canManageOrg
      if (canDirectlyEditRoles) {
        canManageOrg = {
          organization: org,
          role: canDirectlyEditRoles.role,
          direct: true,
          canAssignRoles: canManageRole[canDirectlyEditRoles.role].filter(role => org.organization_configuration.user_roles.includes(role))
        }
      }

      // current user has admin in the parent organization
      let parentOrg = state.organizations.find(stateOrg => stateOrg.id === org.parent_id)

      while (parentOrg) {
        // eslint-disable-next-line camelcase
        const canParentOrgAdminManageRole = parentOrg?.role_assignments?.find(assignment => assignment.lte_user?.id === state.userData.id && assignment.role === 'ADMIN')
        if (canParentOrgAdminManageRole) {
          if (!canManageOrg) canManageOrg = {}

          if (!canManageOrg.organization)canManageOrg.organization = parentOrg
          canManageOrg.role = canParentOrgAdminManageRole.role
          canManageOrg.indirect = true

          if (!canManageOrg.canAssignRoles && (!canDirectlyEditRoles || canDirectlyEditRoles.role !== 'ADMIN')) {
            if (parentOrg.dtype === 'NationalSalesPartner') {
              canManageOrg.canAssignRoles = canManageRole.ADMIN.filter(role => org.organization_configuration.user_roles.includes(role))
            } else {
              canManageOrg.canAssignRoles = canManageRole.SUPPORT.filter(role => org.organization_configuration.user_roles.includes(role))
            }
          }
        }
        // Get next parent organization in the structure
        parentOrg = state.organizations.find(stateOrg => stateOrg.id === parentOrg.parent_id)
      }

      return canManageOrg
    })

    return isArray ? ret : ret[0]
  },

  supportEmail (state, getters) {
    return 'support@whistle-report.com'
  }

}
