<template>
  <v-btn
    @click.stop="click"
    :icon="icon"
    :type="type"
    :color="color"
    :disabled="isLoading || disabled"
  >
    <slot></slot>
    <v-progress-circular
      v-if="isLoading && !noSpinner"
      indeterminate
      :width="circularWidth"
      class="progress"
      :size="circularSize"
      :style="`margin-left: ${marginFromText}px`">
    </v-progress-circular>
  </v-btn>
</template>

<script>
export default {
  name: 'AwaitButton',
  emits: ['click'],
  props: {
    color: {
      required: false,
      type: String
    },

    circularWidth: {
      type: String,
      required: false,
      default: '3'
    },

    circularSize: {
      type: Number,
      required: false,
      default: 24
    },

    flat: {
      type: Boolean,
      required: false,
      default: false
    },

    type: {
      type: String,
      required: false
    },

    marginFromText: {
      type: Number,
      required: false,
      default: 10
    },

    disabled: {
      type: Boolean,
      default: false
    },

    // whether a click event should be thrown
    // if set to true, 'callback' will not be executed and the loading is triggered by 'customLoading'
    emitClick: {
      type: Boolean,
      default: false
    },

    // shows the loading animation and disables the button if 'emitClick' is set to true
    customLoading: {
      type: Boolean,
      default: false
    },

    // function that is executed on click of 'emitClick' is false
    callback: {
      type: Function,
      default: () => () => null
    },

    noSpinner: {
      type: Boolean,
      default: false
    },

    icon: {
      type: Boolean,
      default: false
    },
    on: {
      type: Object
    },
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    isLoading () {
      return this.emitClick ? this.customLoading : this.loading
    }
  },
  methods: {
    click () {
      if (this.isLoading) {
        return
      }

      if (this.emitClick) {
        this.$emit('click')
      } else {
        this.handleCallback()
      }
    },

    async handleCallback () {
      this.loading = true
      try {
        await this.callback()
      } finally {
        this.loading = false
      }
    }
  }

}
</script>

<style lang="stylus" scoped>

</style>
