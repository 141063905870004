<template>
  <div>
    <Dialog
      contentClass="mail-trigger-dialog"
      :model-value="modelValue"
      allow-overflow
      @update:modelValue="close()"
      @cancel="close()"
      @submit="triggerMails()"
      :title="$t('mailTrigger.title')"
      :cancel-text="$t('mailTrigger.cancel')"
      :submit-text="$t('mailTrigger.submit')"
    >
      <div class="trigger-content">
        <label>{{ $t('mailTrigger.date') }}</label>
        <DateField
          :model-value="date"
          @update:modelValue="dateMissing = false; dateInvalid = false"
          :mustBeInThePast="false"
          @invalidInput="dateInvalid = true"
          :invalid="dateInvalid || dateMissing"
          :key="key"
          :placeholder="nowDate"
        />
        <div class="divider"></div>
        <label>{{ $t('mailTrigger.time') }}</label>
        <VueDatePicker v-model="time" time-picker/>
        <!--            <v-menu
                  max-height='90%'
                  ref="menu"
                  v-model="showTimepicker"
                  @update:modelValue="timeMissing = false"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ props }">
                    <v-text-field
                      v-model="time"
                      variant="outlined"
                      class="time-input"
                      :rules="[v => ! timeMissing]"
                      prepend-inner-icon="access_time"
                      readonly
                      :placeholder="nowTime"
                      v-bind="props"
                    ></v-text-field>
                  </template>
                 <v-time-picker-->
<!--            v-if="showTimepicker"-->
<!--            v-model="time"-->
<!--            full-width-->
<!--            format="24hr"-->
<!--            @click:minute="$refs.menu.save(time)"-->
<!--          ></v-time-picker>-->
      </div>
    </Dialog>

    <v-snackbar
      color="primary"
      v-model="showSnackbar"
      top
      absolute
      style="z-index: 100000">
      {{ snackbarText }}
      <template v-slot:actions>
        <v-icon @click="showSnackbar = false">close</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Dialog from './Dialog'
import DateField from './inputField/DateField'
import { mapActions } from 'vuex'
import dayjs from "@/plugins/day"
import VueDatePicker from '@vuepic/vue-datepicker'

export default {
  name: 'MailTrigger',
  components: {
    DateField,
    Dialog,
    VueDatePicker
  },
  props: {
    modelValue: {
      type: Boolean
    }
  },
  data () {
    return {
      date: '',
      time: {hours:'00',minutes:'00',seconds:'00'},
      showTimepicker: false,
      showSnackbar: false,
      dateMissing: false,
      timeMissing: false,
      dateInvalid: false,
      snackbarText: '',
      key: Date.now()
    }
  },
  computed: {
    nowDate () {
      return dayjs().format('DD.MM.yyyy')
    },

    nowTime () {
      return dayjs().format('HH:mm')
    }
  },
  methods: {
    ...mapActions(['triggerMailNotifications']),

    close () {
      this.resetValidation()
      this.$emit('update:modelValue', false)
      this.date = ''
      this.time = ''
      this.key = Date.now()
    },

    resetValidation () {
      this.dateInvalid = false
    },

    async triggerMails () {
      if (this.dateInvalid) {
        return
      }

      const dateMoment = dayjs(this.date || this.nowDate, 'DD.MM.YYYY')
      const timeMoment = this.time || dayjs(this.time || this.nowTime, 'HH:mm')
      if(this.time)
        this.time.humanFormat = this.time.hours+':'+timeMoment.minutes

      dateMoment.hour(timeMoment.hour())
      dateMoment.minute(timeMoment.minute())
      const timestamp = dateMoment.toISOString()

      await this.triggerMailNotifications(timestamp)
      this.showSnackbar = true
      this.snackbarText = this.$t('mailTrigger.snackbar', { date: this.date || this.nowDate, time: (this.time && this.time.humanFormat) || this.nowTime })

      this.close()
    }
  }
}
</script>

<style>
  .mail-trigger-dialog.v-dialog {
    overflow-y: visible !important;
  }
</style>

<style scoped lang="scss">
  .trigger-content {
    display: grid;

    label {
      color: var(--title-text-color);
      font-weight: bold;
    }
  }

  .divider {
    height: 10px;
  }

  .time-input.missing :deep(.v-icon) {
    color: var(--destruction) !important;
  }

</style>
