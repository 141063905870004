import store from '@/store'

const makeExternalError = (code, message, contentType) => {
  let err
  if (contentType && contentType.indexOf('text/html') >= 0) {
    err = new Error()
  } else {
    err = new Error(message)
  }
  err.code = code
  return err
}

async function handleError (response) {
  if (response.status === 401) {
    store.commit('setSessionExpired', true)
  }

  return makeExternalError(response.status, await response.text(), response.headers.get('content-type'))
}

export default {

  async GET (
    { commit, dispatch, state },
    { url, payload = {}, handleFailure = true, preventErrorShown = false }
  ) {
    try {
      payload.credentials = 'include' // essential for the IIS-Authentification
      payload.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'x-auth-token': state.authToken
      }
      const response = await fetch(url, payload)

      if (handleFailure && !response.ok) {
        throw await handleError(response)
      }

      return response
    } catch (error) {
      if (handleFailure && !preventErrorShown) {
        if (error.code >= 400 && error.code < 500) {
          commit('setErrorMessage', `Error ${error.code}: "${error.message}"`)
        }
        commit('showDefaultError')
        throw error
      } else {
        throw error
      }
    }
  },

  async POST (
    { commit, dispatch, state },
    { url, handleFailure = true, body = {}, isFile = false }
  ) {
    try {
      var request = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'x-auth-token': state.authToken
        },
        body: body,
        method: 'POST',
        credentials: 'include' // essential for the IIS-Authentification
      }

      if (isFile) {
        delete request.headers['Content-Type']
        request.body = body
        request.enctype = 'multipart/form-data'
      }

      const response = await fetch(url, request)

      if (handleFailure && !response.ok) {
        throw await handleError(response)
      }

      return response
    } catch (error) {
      if (handleFailure && error.code !== 401) {
        if (error.code === 503 || (error.code >= 400 && error.code < 500)) {
          commit('setErrorMessage', `Error ${error.code}: "${error.message}"`)
        }
        commit('showDefaultError')
        throw error
      } else {
        throw error
      }
    }
  },

  async DELETE (
    { commit, state },
    { url, body, handleFailure = true }) {
    try {
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'x-auth-token': state.authToken
        },
        method: 'DELETE',
        body: body,
        credentials: 'include' // essential for the IIS-Authentification
      })

      if (handleFailure && !response.ok) {
        throw await handleError(response)
      }

      return response
    } catch (error) {
      if (handleFailure) {
        if (error.code >= 400 && error.code < 500) {
          commit('setErrorMessage', `Error ${error.code}: "${error.message}"`)
        }
        commit('showDefaultError')
        throw error
      } else {
        throw error
      }
    }
  }
}
