<template>
  <div>
    <v-list-item v-if='!miniVariant' two-line title="">
      <div class="list-item">
        <div class="list-item-icon">
          <div :style="miniVariant && {margin:'auto'}"
               :class="{'pointer': !isCallOperator}"
               @click="$router.push({ name: 'UserSettings' })"
               style="width: 40px;">
            <v-img
              style="border-radius: 50%"
              :src="fileName?`/user/${userData.id}/${fileName}`:'/static/images/blank-profile-picture.png'"></v-img>
          </div>
        </div>

        <div class="list-item-content">
          <div class="pointer"
               @click="$router.push({ name: 'UserSettings' })">
            <div class="username" v-if="!miniVariant && !isMiniVariant">{{
                userData.username
              }}
            </div>
          </div>
        </div>

        <div class="list-item-action">
          <v-tooltip top>
            <template v-slot:activator="{ props }">
              <v-btn
                @click="logout()"
                icon
                v-bind="props"
                variant="flat"
                aria-expanded="true"
              >
                <v-icon size="x-large">login</v-icon>
              </v-btn>
            </template>
            <label>{{ $t(`general.logoutTooltip`) }}</label>
          </v-tooltip>

        </div>
      </div>


    </v-list-item>

    <v-tooltip v-if='miniVariant' right>
      <template v-slot:activator="{ props }">
        <v-list-item
          class='miniVariant'
          style='border-bottom: 1px solid var(--border-gray);'
          v-bind='props'
          title=""
          @click="!isCallOperator ? $router.push({ name: 'UserSettings' }) : ''"
          link
        >
          <v-avatar :style="miniVariant && {margin:'auto'}"
                    :class="{'pointer': !isCallOperator}"
                    @click="!isCallOperator ? $router.push({ name: 'UserSettings' }) : ''"
                    width="24">
            <v-img
              :src="fileName?`/user/${userData.id}/${fileName}`:'/static/images/blank-profile-picture.png'"></v-img>
          </v-avatar>
        </v-list-item>
      </template>
      <label>{{ userData.username }}</label>
    </v-tooltip>

    <v-tooltip v-if='miniVariant' right>
      <template v-slot:activator="{ props }">
        <v-list-item title="" v-bind='props' @click="logout()" link style='border-bottom: 1px solid var(--border-gray);'>
          <v-list-item-action style='height:67px'>
            <v-icon>login</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
      <label>{{ $t(`general.logoutTooltip`) }}</label>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'NavigationLogout',
  props: {
    isMiniVariant: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant,
      userData: state => state.userData,
      users: state => state.users
    }),
    ...mapGetters([
      'isCallOperator'
    ]),
    fileName () {
      // eslint-disable-next-line camelcase
      return this.users.find(user => user.id === this.userData.id)?.profile_picture_filename || null
    }
  },
  methods: {
    ...mapActions(['logout'])
  }
}
</script>

<style lang="scss" scoped>

.username {
  padding-left: 10px;
  word-break:  break-word;
  white-space: pre-line;
}

.miniVariant {
  padding: 6px
}

</style>
