import apollo from '../../apollo'
import gql from 'graphql-tag'
import {getOffset, getUsersFilterQueryConditions, sortQueryBuilder, userFields} from '../graphqlFragments'
import i18next from "i18next";
import vuetify from "@/plugins/vuetify";

function snakeToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  )
}

export function isDataURI(text) {
  if (!text) {
    return false;
  }

  try {
    atob(text.split(',')[1]);
    return true;
  } catch (e) {
    return false;
  }
}

export function dataURIToBlob(dataURI) {
  return _dataURIToBlob(dataURI)
}

function _dataURIToBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], {type: mimeString})
}

/**
 * @typedef typeRoleAssignments
 * @property {string} organizationId
 * @property {string} lteUserId
 * @property {string} role
 */

/**
 * @typedef typeCalculateRoleAssignmentDifferencesArgs
 * @property {typeRoleAssignments[]} old
 * @property {typeRoleAssignments[]} new
 */

/**
 * @typedef typeRoleAssignmentDelta
 * @property {typeRoleAssignments[]} remove
 * @property {typeRoleAssignments[]} set
 */

/**
 * @param {typeCalculateRoleAssignmentDifferencesArgs} diff
 * @returns {typeRoleAssignmentDelta}
 */
export function calculateRoleAssignmentDifferences(diff) {
  const remove = diff.old.filter(oldRole =>
    diff.new.filter(newRole => oldRole.organizationId === newRole.organizationId && oldRole.lteUserId === newRole.lteUserId).length === 0
  )
  const set = diff.new.filter(newRole =>
    diff.old.filter(oldRole => oldRole.role === newRole.role && oldRole.organizationId === newRole.organizationId && oldRole.lteUserId === newRole.lteUserId).length === 0
  )

  return {remove, set}
}

export default {
  async loadAllUsers({state, commit, getters}) {
    const response = await apollo(state).query({
      query: gql`
        query lte_user {
          lte_user{
            ${userFields(getters.isOrganizationAdminOrSupport, getters.isTranslator || getters.isLteAdmin, getters.isCallOperator)}
          }
        }
      `
    })
    if (response.data) {
      commit('setAllUsers', response.data.lte_user)
    }
  },
  async loadAllUsersFiltered({state, commit, getters, dispatch}) {
    if (getters.isTranslator) {
      console.warn('Translators should not attempt loading users')
      return
    }

    const conditions = getUsersFilterQueryConditions(state.usersTableOptions.search, state.accountsFilter, getters.isLteAdmin, state.usersTableOptions.filter.organization.map(organization => organization.id), state.usersTableOptions.filter.organization.length > 0, state.accountsFilter.length>0)
    const order = sortQueryBuilder(state.usersTableOptions.orderBy)

    const response = await apollo(state).query({
      query: gql`
        query lte_user($where:lte_user_bool_exp!, $order_by: [lte_user_order_by!],$limit: Int, $offset: Int) {
          lte_user(
            where: $where,
            limit: $limit,
            offset: $offset,
            order_by: $order_by
          ) {
            ${userFields(getters.isOrganizationAdminOrSupport, getters.isTranslator || getters.isLteAdmin, getters.isCallOperator)}
            ${getters.isLteAdmin ? 'basic_user_type' : ''}
            organization {
              role_assignments(where: {role: {_in: ["ADMIN"]}}) {
                lte_user_id
              }
            }
          }
          lte_user_aggregate(where: $where) {
            aggregate {
              count
            }
          }
        }
      `,
      variables: {
        where: conditions,
        offset: getOffset(state.usersTableOptions.paginator.page, state.usersTableOptions.paginator.itemsPerPage),
        limit: state.usersTableOptions.paginator.itemsPerPage,
        order_by: order,
      }
    })
    if (response.data) {
      commit('setAllUsersFiltered', {data: response.data.lte_user, totalCount: response.data.lte_user_aggregate.aggregate.count})
    }
  },

  /** @param {typeRoleAssignmentDelta} to */
  async updateRoleAssignment({state, dispatch, commit}, to) {
    const rolesToRemove = to.remove
    const rolesToSet = to.set
    const rolesToRemoveSelf = rolesToRemove.filter(role => role.lteUserId === state.userData.id)
    if (rolesToRemoveSelf.length > 0) {
      rolesToRemoveSelf.forEach(roleToRemoveSelf => {
        var removalIndex = rolesToRemove.indexOf(roleToRemoveSelf)
        rolesToRemove.splice(removalIndex, 1)
      })
    }
    // TODO Simplify into a single request
    // Set roles before removing them, because during removing user without any role set isActive to false
    let resposne2 = null
    if (rolesToSet.length > 0) {
      resposne2 = await dispatch('POST', {
        url: '/rest/role-assignments/set/',
        body: JSON.stringify(rolesToSet)
      })
    }
    let resposne1 = null
    if (rolesToRemove.length > 0) {
      resposne1 = await dispatch('POST', {
        url: '/rest/role-assignments/remove/',
        body: JSON.stringify(rolesToRemove)
      })
    }
    let response3 = null
    if (rolesToRemoveSelf.length > 0) {
      response3 = await dispatch('POST', {
        url: '/rest/role-assignments/remove/',
        body: JSON.stringify(rolesToRemoveSelf)
      })
    }

    resposne1 = resposne1 === null || resposne1.ok
    resposne2 = resposne2 === null || resposne2.ok
    response3 = response3 === null || response3.ok

    if (resposne1 && resposne2 && response3) {
      return true
    }
    return false
  },

  async updateUserRoleAssignment({dispatch, commit}, userRoles) {
    const oldRoles = userRoles.old
    const newRoles = userRoles.new
    let rolesToRemove = []
    let rolesToSet = newRoles
    if (oldRoles) {
      rolesToRemove = oldRoles.filter(oldRole =>
        newRoles.filter(newRole => oldRole.organization_id === newRole.organization_id).length === 0
      )
      rolesToSet = newRoles.filter(newRole =>
        oldRoles.filter(oldRole => oldRole.role === newRole.role && oldRole.organization_id === newRole.organization_id).length === 0
      )
    }
    const rolesToRemoveMapped = rolesToRemove.map(role => ({
      lteUserId: userRoles.id,
      organizationId: role.organization_id,
      role: role.role
    }))

    const rolesToSetMapped = rolesToSet.map(role => ({
      lteUserId: userRoles.id,
      organizationId: role.organization_id,
      role: role.role
    }))
    return await dispatch('updateRoleAssignment', {set: rolesToSetMapped, remove: rolesToRemoveMapped})
  },

  async updateOrganizationRoleAssignment({dispatch, commit}, roleAssignments) {
    const oldAssignments = JSON.parse(JSON.stringify(roleAssignments.old))
    const newAssignments = JSON.parse(JSON.stringify(roleAssignments.new))
    const orgId = roleAssignments.orgId

    const assignmentsToRemove = oldAssignments.filter(
      oldRole => newAssignments.filter(
        newRole => newRole.lte_user.id === oldRole.lte_user.id
      ).length === 0)

    const assignmentsToSet = newAssignments.filter(
      newRole => oldAssignments.filter(
        oldRole => oldRole.lte_user.id === newRole.lte_user.id && oldRole.role === newRole.role
      ).length === 0)

    const assignmentsToRemoveMapped = assignmentsToRemove.map(role => ({
      lteUserId: role.lte_user.id,
      organizationId: orgId,
      role: role.role
    }))

    const assignmentsToSetMapped = assignmentsToSet.map(role => ({
      lteUserId: role.lte_user.id,
      organizationId: orgId,
      role: role.role
    }))

    return await dispatch('updateRoleAssignment', {toSet: assignmentsToSetMapped, toRemove: assignmentsToRemoveMapped})
  },

  async updateProfile({dispatch, commit}, user) {
    const body = {...user}
    delete body.profile_picture
    body.email = body.email.toLowerCase()

    // to add a translator language:
    if (user.assignedTranslation?.length) {
      body.translator_languages = user.assignedTranslation.map(slug => ({
        locale: slug
      }))
    }

    const response = await dispatch('POST', {
      url: `/rest/user/${user.id}/update-profile`,
      body: JSON.stringify(body),
      handleFailure: false
    })

    if (response.ok) {
      dispatch('loadAllUsers')
      dispatch('loadAllUsersFiltered')
    }

    return response
  },

  async createUser({dispatch, commit, state}, user) {
    const userOrganization = state.organizations.find(el => el.id === user.organization_id)

    user.basic_user_type = 'SME'
    if (!userOrganization) {
      switch (user.role) {
        case 'CALL_OPERATOR' :
          user.basic_user_type = 'CALL_OPERATOR'
          break
        case 'LTE_ADMIN':
          user.basic_user_type = 'LTE_ADMIN'
          break
        case 'TRANSLATOR':
          user.basic_user_type = 'TRANSLATOR'
          break
      }

      user.role = 'USER' // todo make backed accept null
    } else if (userOrganization.dtype === 'LawFirm') {
      user.basic_user_type = 'LAW'
    }

    const input = {...user}
    delete input.assignedTranslation

    input.email = input.email.toLowerCase()
    const body = new FormData()
    if (input.profile_picture) {
      if (isDataURI(input.profile_picture)) {
        body.append('image', dataURIToBlob(input.profile_picture))
      }
      delete input.profile_picture
    }

    for (const key of Object.keys(input)) {
      body.append(snakeToCamel(key), input[key])
    }

    // to add a translator language:
    if (user.assignedTranslation?.length) {
      user.assignedTranslation.forEach(slug => {
        body.append('translatorLanguages', slug)
      })
    }

    const response = await dispatch('POST', {
      url: '/rest/user/create?set-password=false',
      body: body,
      isFile: true,
      handleFailure: true
    })

    if (response.status === 409) {
      dispatch('setEmailAlreadyExistsDialog', true)
      return
    } else if (response.status === 401) {
      dispatch('setSessionExpired', true)
      return
    } else if (response.status === 403) {
      dispatch('setErrorMessage', (i18next.t('users.filter.tooManyUsers')))
      dispatch('showDefaultError')
      dispatch('loadAllUsers')
      dispatch('loadAllUsersFiltered')
      return
    } else if (!response.ok) {
      dispatch('showDefaultError')
      return
    }

    await dispatch('loadAllUsers')
    dispatch('loadAllUsersFiltered')
    dispatch('loadOrganizations')
    dispatch('loadOrganizationsFiltered')
    return response
  },

  async updateProfilePicture({dispatch, commit}, user) {
    if (!isDataURI(user.profile_picture)) {
      return;
    }
    const body = new FormData()
    body.append('image', dataURIToBlob(user.profile_picture))
    await dispatch('POST', {
      url: `/rest/user/${user.id}/update-picture`,
      isFile: true,
      body: body
    })
  },

  async toggleUserActive({dispatch, commit}, user) {
    await dispatch('POST', {
      url: `/rest/user/${user.id}/set-active?active=${!user.active}`
    })

    dispatch('loadAllUsers')
    dispatch('loadAllUsersFiltered')
    commit('updateUser', {...user, active: !user.active})
  },

  async deleteUser({dispatch, commit}, user) {
    await dispatch('DELETE', {
      url: `/rest/user/${user.id}/delete`
    })

    dispatch('loadAllUsers')
    dispatch('loadAllUsersFiltered')
    dispatch('loadOrganizations')
  },

  async resetPassword({dispatch}, user) {
    const encodedEmail = encodeURIComponent(user.email);
    const response = await dispatch('POST', {
      url: `/rest/user/reset-password?email=${encodedEmail}`
    })
    const tempPassword = await response.text()
    return tempPassword
  },

  changePassword({state, dispatch}, {newPassword, oldPassword}) {
    return dispatch('POST', {
      url: `/rest/user/${state.userData.id}/change-password`,
      handleFailure: false,
      body: JSON.stringify({
        old: oldPassword,
        new: newPassword
      })
    })
  },

  async hideWelcomePopup({state, dispatch, commit}) {
    const user = state.users.find(user => user.id === state.userData.id)
    if (user) {
      user.show_welcome_popup = false
      await dispatch('updateProfile', user)
      commit('setShowWelcomePopup', false)
    }
  },

  async changeSetupCompleted({state, dispatch, commit}) {
    const user = state.users.find(user => user.id === state.userData.id)
    if (user) {
      user.setup_completed = true
      await dispatch('updateProfile', user)
      commit('setSetupCompleted', true)
    }
  },
  // TODO consolidate with authActions.changeLanguage
  async changeUserLanguage({state, commit, getters, dispatch}, {userId = null, language, save = true}) {
    if (!getters.isWhb && save) {
      dispatch('POST', {
        url: `/rest/user/${userId || state.userData.id}/change-language`,
        handleFailure: false,
        body: JSON.stringify({
          language: language
        })
      })
    }
    // only change when whistleblower or its own user
    if (getters.isWhb || getters.isCallOperator || state.userData.id === userId) {
      await i18next.changeLanguage(language)
      vuetify.locale.current = language
      commit('changeLanguage', language)
    }
  },

  async updateMfaSettings({dispatch, state, commit, getters}, {
    mfaMethod,
    mfaGoogleSecret,
    mfaPhone,
    mfaPhoneCountryCode
  }) {
    const response = await dispatch('POST', {
      url: `/rest/user/${state.userData.id}/update-mfa-settings`,
      body: JSON.stringify({
        mfa_method: mfaMethod,
        mfa_google_secret: mfaGoogleSecret,
        mfa_phone: mfaPhone,
        mfa_phone_country_code: mfaPhoneCountryCode
      })
    })

    return response
  }
}
